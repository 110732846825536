export const Configuration = {
    /*                                        General App createConfiguration                                          */
    reelsColor: 0x0000ff,                       // Javascript Hexidecimal color which will be shone on the reels, its a gradient hemisphere light going from reelsColor to white (top to bottom)
    reelIlluminationColor: 0xffffff,            // Javascript Hexidecimal color of the light which shines on the front win reels illuminating them
    backgroundColor: 0x000000,                  // Javascript Hexidecimal Background color of the whole fruit machine scene
    freePlay: false,                            // Setting to true will enable free play and no coins. To false will mean user has to have coins to spin
    startingCoins: 1000,                        // How many coins the user will start play with if freePlay is set to false
    costPerSpin: 50,                            // How many coins it costs for user to spin the reel if freePlay is set to false
    enableHelperTips: true,                     // True or false weather helper tips are enabled and can be viewed - if set to false, in addition you will need to comment out the `${require("./helper-tips.html")}` statement in the fruitMachine.html

    /*                                        Reels Configuration                                                     */
    numberOfReels: 4,                           // Number of reels - Minimum needs to be 2
    reelWidth: 10,                              // How wide the reels are
    reelSpacing: 5,                             // How much space between each reel
    reelSize: 15,                               // How big the reels are
    reelWinProbability: 6,                      // The probability as a fraction that the next reel will be the same as the previous (every 1 in reelWinProbability)

    /*                                        Spin Animation Configuration                                            */
    numberOfFullRotations: 5,                   // How man complete rotations before landing on the next fruit
    spinTimeout: 10,                            // Number of milliseconds to wait before spinning the next reel
    degreesToMoveBackBeforeSpinning: 5,         // How many degrees the reels reverse before spinning
    spinReelsDuration: 2200,                    // How long it takes for the number of full rotations to happen in milliseconds

    /*                                        Win Animation Configuration                                              */
    zoomThroughReelsXGap: 50,                   // The gap between the the edge of the reels and the camera when zooming through
    zoomThroughReelsZPercentage: 80,            // The size percentage of the reel size in which the cameras z coordinate should be when zooming
    winAnimationDuration: 5000,                 // How long it takes for the win animation to complete in milliseconds
    winJudderDuration: 60,                      // How long in milliseconds the reels judder before proceeding with win animation
 
    /*                                        Nudge & Hold Configuration                                               */ 
    nudgeProbability: 4,                        // The probability as a fraction that user will get a nudge after spinning (every 1 in nudgeProbability)
    nudgeDuration: 500,                         // The time in milliseconds it takes for a nudge to complete
    holdProbability: 4,                         // The probability as a fraction that user will get a hold after spinning (every 1 in holdProbability)
    flashButtonsSpeed: 0.01,                    // How quickly the buttons flash and move up and down when user has a nudge or hold

    /*                                         Bonus Configuration                                                     */  
    enableBonus: true,                          // If set to true a bonus face will be added to 1 of the reels and the other reels will have a random fruit added
    bonusStoppaReelSpeed: 0.6,                  // The speed at which the reel turns whilst stoppaReel bonus game is being played
    bonusStoppaReelIncrement: 0.7,              // How much faster per reel the reels get when moving to the next one whilst stoppaReel bonus game is being played
    reelStrikeSpeed: 5,                         // The speed the light revolves the reels for the reel strikes
    streakBounds: 50,                           // When user gets a streak, the payouts of each roll will be a random fruit value between 0 to this.streakBounds percent of the lowest value fruits
    superStreakBounds: 80,                      // When user gets a super streak, the payouts of each roll will be a random fruit value between 0 to this.superStreakBounds percent of the lowest value fruits
    megaStreakBounds: 100,                      // When user gets a streak, the payouts of each roll will be a random fruit value between 0 to this.megaStreakBounds percent of the lowest value fruits

    /*                                          Number Trail Configuration                                              */
    enableNumbers: true,                        // If set to true, random numbers between 1-5 will be added to a random number of reels and bonus trail will be enabled
    numbersBonus: 6,                            // How many numbers needed for a bonus on the number reel
    numberTrailHeldProbability: 3,              // The probability as a fraction a numberTrailHeld will be given on a spin (every 1 in numberTrailHeldProbability)
    numberMaximumOnReels: 5,                    // The highest number that will appear on the reels - numbers are randomely generated between 0 and numberMaximumOnReels
    numberTrailHeldColor: 0x76ef00,             // Javascript Hexidecimal color the faces of the numberReel go when the trail is held
    numberHighlightColor: 0x000000,             // Javascript Hexidecimal color the current face on the number reel will go (the one highlighting the number to user is on)
    numberBonusColor: 0xFF6600,                 // Javascript Hexidecimal color for the color of the Bonus number reel face set by numbersBonus
    numberFeatureStartColor: 0xFF6600,          // Javascript Hexidecimal color for the color of the Feature Start number reel face which will be last in numbers array
    
    /*                                          Feature Board Configuration                                             */
    cubeColor: 0xF05E23,                        // The color of the feature board cubes
    diceColor: 0xFFFFFF,                        // The color of the feature board dice
	numberOfOuterBoardSquares: 20, 				// Number of squares in the outer feature baord (must be multiple of 4)
    numberOfInnerBoardSquares: 16, 				// Number of squares in the inner feature board (must be multiple of 4)
    cubeSize: 3.5,								// The size of the feature board cubes
    diceSize: 5,								// The size of the sice on the feature board
    diceSpinDuration: 1000,						// How long in milliseconds it takes the dice to spin the next value
    diceSpinFullRotations: 1, 					// number of full rotations the dice does (in a random axis) before landing on next number
    diceGambleWinProbability: 4, 				// Providing the current dice number is not 12 (highest) or 1 (lowest) - The probability as a fraction that the user will win a dice gamble HI/LO (every 1 in reelWinProbability)
    streakStrengthMax: 10,						// maximum intervals of streak strength, this is relative in that, if you have 20 or 5, if user reaches maximum they will still win the same range amount of money 
    moveCubeSpeed: 200,                         // How long, in milliseconds, it takes for the light to move from one cube to another. Used in normal dice spins AND stoppa function
    stoppaOrTaxiTimeout: 10000,                 // How long, in milliseconds, the user has to click whilst stoppa or taxi are running before the machine chooses
    rollEvenOddSpeed: 5                         // The speed in which the dice rolls when you have a roll even/odd
}