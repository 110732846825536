require("@babel/polyfill");
require('./index.scss');

import rwx from 'roseworx';

import { FruitMachineCore } from './fruitMachineCore';

new FruitMachineCore('#fruit-machine-container');

/*
  TODO  

  finish off writing instructions
  add help text to toggler

  meta tags for google surfacing and SEO

  find mininmal viable screen dimensions for suitable play and disabled for lower with message saying use bigger device
  Check if it works on IE on windows partition and different browsers and message saying cant use if not
*/

/*
  Future imporovements
  Feature board - game when you get all icons
  Win animation - some sort of light show/fireworks when zooming through the reels
  Feature-board - set the point light to center of each feature board cube, will need to determine which side of screen its on and plus or minus x percentage based on that, same with y. Currently its a bit offset 
  Different themes fruit machines based on images and change background to a theme color or theme image repeat
  Fruit machine - bank nudges
  animated switching on and off of the lights between games
  Sort out featureBoard cubes, make sure none of the same are next to eachother and at least one of each is there.
  Loading animation instead of plain text
  fade out the dom-element-hide and show mayb z-opaque it?
*/

/*
  Known Bugs
  Increasing reelWidth fucks up the fruit textures
  sometimes when nudging, the reel does that thign where it spins backwards to index 0 (im assuming) need to recreate (perhaps set all reelIndexes to fruits.length and see if its that)
  the animated popout has a line with a slightly darker portion to the right side of it - very noticeable
  Number reel not working as expect, if no trail held and nudge with numbers on the same reel, the correct number gets highlighted but not rotated
  coin count up on featureBoard is pulsating when initial feature board
  when going back to fruitMachine from feature board if reel is held it seems to stay held
  tippy animation not working
*/

/*
  Priority Order
  0. Feature Start
  1. Win
  2. Bonus
  3. Hold
  4. Nudges
*/