const helpers = {

  showDOMelements(show, identifiers)
  {
    for(let dm of identifiers)
    {
      let el = document.getElementById(dm)
      show && el.classList.remove('dom-element-hide');
      !show && el.classList.add('dom-element-hide');
    }
  }
}

export default helpers;