class MeasureMeter {
	constructor(selector, numberOfIntervals, initialValue=0)
	{
		this.measureMeter = document.querySelector(selector);
		if(!this.measureMeter){return;}
		this.numberOfIntervals = numberOfIntervals;
		this.prevValue = initialValue;
		this.timeout = 100;
		this.createIntervals();
		for(let [index,i] of this.intervals.entries())
		{
			if(index==initialValue){break;}
			i.classList.add('measure-meter-interval-fill');
		}
	}

	createIntervals()
	{
		let el;
		this.intervals = [];
		for(let i=0;i<this.numberOfIntervals;i++)
		{
			el = document.createElement('span');
			el.classList.add('measure-meter-interval');
			this.measureMeter.appendChild(el);
			this.intervals.push(el);
		}
	}

	to(amount)
	{
		if(this.prevValue == amount){return;}
		let amt;
		amt = amount < 0 ? 0 : amount;
		amt = amount > this.numberOfIntervals ? this.numberOfIntervals : amount;
		let interval = setInterval(()=>{
			if(this.prevValue==amt || amt<0)
			{
				clearInterval(interval);
			}
			else if(this.prevValue > amt)
			{
				this.intervals[this.prevValue-1].classList.remove('measure-meter-interval-fill');
				this.prevValue-=1;
			}
			else if(this.prevValue < amt)
			{
				this.intervals[this.prevValue].classList.add('measure-meter-interval-fill');
				this.prevValue+=1;
			}
		}, this.timeout);
	}
}

export default MeasureMeter;