import * as THREE from 'three';
import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer.js';

import OneUp from './oneUp/oneUp.js';

import rwxMath from 'roseworx/js/helpers/rwxMathHelpers';
import rwxMisc from 'roseworx/js/helpers/rwxMiscHelpers';
import rwxGeometry from 'roseworx/js/helpers/rwxGeometryHelpers';
import rwxAnimate from 'roseworx/js/helpers/rwxAnimateHelpers';

export class FeatureBoard {
	constructor()
	{
		this.featureBoardDevelop = false;
	}

	//
	// *** CREATORS
	// *** These methods are responsible for creating the objects which render on the actual page
	//

	createFeatureBoard()
	{
		this.createCubes();
		this.createDice();
		this.createHeartsAndIcons();
		this.createDOMHolders();
		this.fbGeometryGroup.add(this.fbCubesGroup);
		this.fbGeometryGroup.add(this.fbPersistentZGroup);
		this.fbGeometryGroup.rotation.y = rwxGeometry.toRadians(-90);
		this.fbGeometryGroup.position.set(this.featureCameraPositionDefault.x+this.fbCameraDistance, this.featureCameraPositionDefault.y, this.featureCameraPositionDefault.z);	
		this.scene.add( this.fbGeometryGroup );
		
		// setTimeout(()=>{
		// 	console.log(this.getCurrentSquare());
		// }, 5000);

	}

	createHeartsAndIcons()
	{
    let data = [
	    {
	    	id: 'heart',
	    	image: require('./fruitMachineAssets/heart.png'),
	    	variable: 'heartsArray'
	    },
	    {
	    	id: 'icon',
	    	image: require('./fruitMachineAssets/icon.png'),
	    	variable: 'iconsArray'
	    }
    ]

    for(let d of data)
    {
	    for(let h=0;h<this.maxNumberOfIcons;h++)
	    {
	    	let ou = new OneUp(d.image, `feature-board-${d.id}${h+1}-container`);
	    	this[d.variable].push(ou)
	    	ou.getNode().classList.add('dom-element-hide');
	    }
    }
	}

	createDOMHolders()
	{
		// Invisible cubes which serve as placeholders to set the FB DOM elements into the THREE space
		let geo, mat, mesh, domElement, label;
		for(let de of this.fbDOMElements)
		{
			geo = new THREE.BoxBufferGeometry(1,1,1);
			mat = new THREE.MeshStandardMaterial({color: 0x000000});
			mesh = new THREE.Mesh(geo, mat);
			mesh.position.x = de.position.x;
			mesh.position.y = de.position.y;
			if(de.helperTipID)
			{
				this.checkAndApplyHelperTip(mesh, de.helperTipID);
			}
			domElement = document.getElementById(de.domID);
			label = new CSS2DObject(domElement);
			mesh.add(label);
			mesh.visible = false;
			this.fbPersistentZGroup.add(mesh);
		}
	}

	createDice()
	{
		let diceGeometry, diceMesh, helperTip;
		for(let d=0;d<2;d++)
		{
			let materialArray = [];
			let rotations = {};
			diceGeometry = new THREE.BoxBufferGeometry(this.diceSize,this.diceSize,this.diceSize);
			this.addCubeFaceGroups(diceGeometry);
			materialArray.push(new THREE.MeshStandardMaterial({color: this.diceColor}));
			for(let dn of this.diceNumbers)
			{
				if((d==0 && dn.value == 1) || (d==1 && dn.value == 2))
				{
					materialArray.push(new THREE.MeshStandardMaterial({color: this.diceColor}));
				}
				else
				{
					materialArray.push(new THREE.MeshStandardMaterial( { transparent: true, map:dn.texture } ));
				}
			}
			diceMesh = new THREE.Mesh( diceGeometry, materialArray );
			diceMesh.position.x = d==0 ? -this.diceSize : this.diceSize;
			// 5 = no rotation  
			//3 = x rotation 90 
			//6 = x rotation 180 
			//4 = x rotation 270 2 = first dice y rotation 90 1 = second dice y rotation -90; 0 = second dice y rotation 90 , first dice y rotation -90
			rotations[6] = new THREE.Vector3(rwxGeometry.toRadians(180),0,0);
			rotations[5] = new THREE.Vector3(0,0,0);
			rotations[4] = new THREE.Vector3(rwxGeometry.toRadians(270),0,0);
			rotations[3] = new THREE.Vector3(rwxGeometry.toRadians(90),0,0);
			if(d==0){rotations[2] = new THREE.Vector3(0,rwxGeometry.toRadians(90),0)}
			if(d==1){rotations[1] = new THREE.Vector3(0,-rwxGeometry.toRadians(90),0)}
			rotations[0] = d==0 ? new THREE.Vector3(0,-rwxGeometry.toRadians(90),0) : new THREE.Vector3(0,rwxGeometry.toRadians(90),0);
			diceMesh.rotations = rotations;


			d==0 && this.checkAndApplyHelperTip(diceMesh, 'helper-tip-dice');

			this.dice.push(diceMesh);
			this.fbPersistentZGroup.add(diceMesh);
			this.ableToGambleHI = true;
			this.ableToGambleLO = true;
			this.currentNumber = 10; // interim solution, when dice are created it always shows up as 2 5's
		}
	}

	createCubes()
	{
		const outerCubesPerSide = (((this.numberOfOuterBoardSquares/4)+1)/2);
		const innerCubesPerSide = (((this.numberOfInnerBoardSquares/4)+1)/2);
		let cubeGeometry, cubeBackground, cubeTexture, cubeMesh, counter, bounds, x, y, cubeSpacing, outerStartY, outerStartX, innerStartX, innerStartY, toPush, opacity;
		for(let i=0;i<2;i++)
		{
			counter = i == 0 ? this.numberOfOuterBoardSquares : this.numberOfInnerBoardSquares;
			cubeSpacing = i == 0 ? this.outerBoardCubeSpacing : this.innerBoardCubeSpacing;
			outerStartY = (outerCubesPerSide * cubeSpacing) - cubeSpacing/2;
			outerStartX = -outerStartY;
			innerStartY = (innerCubesPerSide * cubeSpacing) - cubeSpacing/2;
			innerStartX = -innerStartY;
			let indexArrayMap = this.buildTextureToCubeMappingArray(counter, i==0);
			bounds = counter/4;
			opacity = i==0 ? 0 : this.innerBoardInactiveOpacity;
			x = i==0 ? outerStartX : innerStartX;
			y = i==0 ? outerStartY : innerStartY;
			for(let s=0;s<counter;s++)
			{
				cubeGeometry = new THREE.BoxBufferGeometry( this.cubeSize, this.cubeSize, this.cubeSize );
				this.addCubeFaceGroups(cubeGeometry);
				cubeBackground = new THREE.MeshStandardMaterial( { color: this.cubeColor, transparent:true, opacity:opacity } );
				cubeTexture = new THREE.MeshStandardMaterial( { transparent:true, map: this.cubeTextures[indexArrayMap[s]].texture, opacity:opacity } );
				cubeMesh = new THREE.Mesh( cubeGeometry, [cubeBackground, cubeTexture,cubeTexture,cubeTexture,cubeTexture,cubeTexture,cubeTexture] );
				cubeMesh.position.x = x;
				cubeMesh.position.y = y;
				if(i==1)
				{
					cubeMesh.position.z = -this.innerBoardDistance;
				}
				else
				{
					cubeMesh.rotation.x = rwxGeometry.toRadians(-90);
				}

				toPush = i==0 ? this.outerCubes : this.innerCubes;
				toPush.push({functionToRun:this.cubeTextures[indexArrayMap[s]].functionToRun, functionToRunValues:this.cubeTextures[indexArrayMap[s]].functionToRunValues, cube:cubeMesh});

				this.fbCubesGroup.add(cubeMesh);

				if(s <= bounds-1)
				{
					x += cubeSpacing;
				}
				else if(s > bounds-1 && s <= (bounds*2)-1)
				{
					y -= cubeSpacing;
				}
				else if(s > (bounds*2)-1 && s <= (bounds*3)-1)
				{
					x -= cubeSpacing;
				}
				else if(s > (bounds*3)-1)
				{
					y += cubeSpacing;
				}
			}
		}
	}





	//
	// *** MISCELLANEOUS
	//
	//

	endFeature()
	{
		this.endFeatureFlag = true;
    this.showDOMelements(false, this.fbDOMElements.map(de=>de.domID));
    this.switchLights("fruit-machine");
	}

	runCubeAction(dontDoAction)
	{
		const cubes = this.getCurrentBoardCubes();
		if(!dontDoAction)
		{
			cubes[this.currentNumberIndex].functionToRunValues ? this[cubes[this.currentNumberIndex].functionToRun](cubes[this.currentNumberIndex].functionToRunValues) : this[cubes[this.currentNumberIndex].functionToRun]();
		}
		if(cubes[this.currentNumberIndex].functionToRun == "mystery" || cubes[this.currentNumberIndex].functionToRun == "moveToBoard")
		{
			this.ableToGambleHI = false;
			this.ableToGambleLO = false;
		}
		else
		{
			this.ableToGambleHI = this.gambleNumber ? (this.gambleNumber<12) : (this.currentNumber<12);
			this.ableToGambleLO = this.gambleNumber ? (this.gambleNumber>1) : (this.currentNumber>1);
		}

		this.randomFeatureBoardCubeRotationMesh = cubes[this.currentNumberIndex].cube;
		this.rotateFeatureBoardCubeFlag = true;
		cubes[this.currentNumberIndex].cube.rotation.x = rwxGeometry.toRadians(45);
		this.diceRolled = false;
		this.hilo = false;
	}

	diceHasRolled()
	{
		const cubes = this.getCurrentBoardCubes();
		if(this.diceGambled){
			if(this.diceGambleWon)
			{
				this.rwxNoticeBox.setValue("Gamble Won", true);
				this.runCubeAction(this.hilo);
			}
			else
			{
				this.gameOver();
				this.hilo = false;
				this.diceRolled = false;
			}
			return;
		}
		const nos = this.getCurrentNumberOfSquares();
		const newNumber = this.currentNumberIndex+this.currentNumber;
		this.currentNumberIndex = newNumber > nos-1 ? (newNumber-nos) : newNumber;
		let interval = setInterval(()=>{
			this.prevNumberIndex+=1;
			if(this.prevNumberIndex > nos-1){this.prevNumberIndex=0;}
			this.setPointLightToCube(this.prevNumberIndex)
			if(this.prevNumberIndex == this.currentNumberIndex){clearInterval(interval);this.runCubeAction();}
		}, this.moveCubeSpeed);
	}

	buildTextureToCubeMappingArray(counter, outerBoard)
	{
		const reservedIndexes = [];
		const randomsIndexes = [];
		const reserved = this.cubeTextures.filter((ct, index)=>{ct.type=='reserved' && reservedIndexes.push(index); return ct.type == 'reserved'});
		const randoms = this.cubeTextures.filter((ct, index)=>{ct.type=='random' && randomsIndexes.push(index); return ct.type == 'random'});
		const a = [];

		for(let [index,r] of reserved.entries())
		{
			let counter = outerBoard ? r.outerAmountOfSquares : r.innerAmountOfSquares;
			for(let x=0;x<counter;x++)
			{
				a.push(reservedIndexes[index])
			}
		}
		const leftover = outerBoard ? (this.numberOfOuterBoardSquares - a.length) : (this.numberOfInnerBoardSquares - a.length);
		for(let l=0;l<leftover;l++)
		{
			let random = rwxMath.randomInt(0,randoms.length-1)
			a.push(randomsIndexes[random]);
		}
		rwxMisc.shuffleArray(a);
		return a;
	}

	addFeatureBoardValues(config, numberPickerEnabled=true)
	{
		if(config.direction=='minus' && this[config.variable] == 0){return;}
		let withOutNumberPicker = [...config.values].filter(v=>v!='numberPicker');
		let arrayToUse = numberPickerEnabled ? config.values : withOutNumberPicker;
		let randomValue = arrayToUse[rwxMath.randomInt(0,arrayToUse.length-1)];
		if(config.direction == 'minus' && (this[config.variable]-randomValue) < 0)
		{
			randomValue = this[config.variable];
		}
		if(randomValue<0){return;}

		const functionToRun = (val)=>{
			let text = val == 1 ? config.text : `${config.text}s`;
			text = `${config.direction} ${val} ${text}`;
			let v = config.direction == 'minus' ? -val : val;
			this[config.variable] += v;
			this.multiAdding ? this.rwxNoticeBox.setValue(text, true, ()=>{this.multiAdding=false;}) : this.rwxNoticeBox.setValue(text, true);
		}

		if(randomValue == 'numberPicker')
		{
			this.rwxNoticeBox.setValue('How Much?');
			this.hasNumberPicker = true;
			this.numberPicker.setOptions(withOutNumberPicker).then((res)=>{
				functionToRun(res);
				this.hasNumberPicker = false;
			});
		}
		else
		{
			functionToRun(randomValue);
		}
	}





	//
	// *** Functional
	// *** These methods actually do the things which happen during the game
	//

	stopStoppaOrTaxi()
	{
		clearInterval(this.stoppaOrTaxiInterval);
		this.stoppaOrTaxiRunning=false;
		this.runCubeAction();
	}

	setStoppaOrTaxiTimout()
	{
		setTimeout(()=>{
			if(!this.stoppaOrTaxiRunning){return;}
			this.stopStoppaOrTaxi();
		}, this.stoppaOrTaxiTimeout);
	}

	stoppa()
	{
		this.stoppaOrTaxiRunning = true;
		let boardLength = this.getCurrentBoardCubes().length;
		this.stoppaOrTaxiInterval = setInterval(()=>{
			let random = rwxMath.randomInt(0, boardLength-1);
			this.setPointLightToCube(random);
			this.currentNumberIndex = random;
		}, this.moveCubeSpeed);
		this.setStoppaOrTaxiTimout();
	}

	taxi()
	{
		this.stoppaOrTaxiRunning = true;
		let boardLength = this.getCurrentBoardCubes().length;
		this.stoppaOrTaxiInterval = setInterval(()=>{
			this.currentNumberIndex+=1;
			if(this.currentNumberIndex > boardLength-1){this.currentNumberIndex=0;}
			this.setPointLightToCube(this.currentNumberIndex)
		}, this.moveCubeSpeed);
		this.setStoppaOrTaxiTimout();
	}

	moveToBoard()
	{
		if(this.currentBoard == 'outer')
		{
			this.rwxNoticeBox.setValue("Move In", true);
			this.currentBoard = 'inner';
			this.moveInFlag = true;
			this.stoppa();
		}
		else
		{
			this.rwxNoticeBox.setValue("Move Out", true);
			this.currentBoard = 'outer';
			this.moveOutFlag = true;
			this.stoppa();
		}
	}

	multiAdd()
	{
		this.multiAdding = true;
		let to = 0;
		for(let ct of this.cubeTextures)
		{
			if(this.availableForMultiAdd.includes(ct.name))
			{
				let toAdd = rwxMath.randomInt(0,1);
				if(toAdd==1){continue;}
				setTimeout(()=>{
					ct.functionToRunValues ? this[ct.functionToRun](ct.functionToRunValues, false) : this[ct.functionToRun]();
				}, to);
				to+=750;
			}
		}
	}

	fbBonus()
	{
		this.hasBonus = true;
		let arr = this.currentBoard == "outer" ? this.featureBoardBonuses : this.featureBoardBonuses.filter(fb=>fb.displayName!="Move In");
		this.setTombola(arr, 'hasBonus');
	}

	addHeart()
	{
		if(this.hasExtraLife()){return;}
		else
		{
			this.heartsArray[this.numberOfHearts].up();
			this.numberOfHearts += 1;
			this.hasExtraLife() ? this.rwxSlideTicker.setValue("Extra Life") : this.rwxNoticeBox.setValue("Add Heart", true);
		}
	}

	addIcon()
	{
		if(this.hasGame()){return;}
		this.iconsArray[this.numberOfIcons].up();
		this.numberOfIcons += 1;
		this.rwxNoticeBox.setValue("Add Icon", true);
		if(this.hasGame())
		{
			this.rwxSlideTicker.setValue("Coming Soon");
			// Launch to Game
			// highjack click events so they dont run board
			// reset evyerhting after game ran
			return;			
		}
	}

	mystery()
	{
		this.hasMystery = true;
		this.setTombola(this.featureBoardMystery, 'hasMystery');
	}

	gameOver()
	{
		if(this.hasExtraLife())
		{
			this.numberOfHearts = 0;
			this.heartsArray.map(h=>h.down());
			this.rwxSlideTicker.setValue("Extra Life");
			return;
		}
		if(this.featureBoardDevelop){return;}
		this.rwxSlideTicker.setValue("Game Over");
		this.endFeature();
	}

	cancelCollect()
	{
		clearInterval(this.collectionInterval);
		this.rwxNoticeBox.close();
		this.collectionFlashing = false;
		this.collectables.map((c)=>{
			document.getElementById(c.domID).classList.remove('collection-background');
		});
	}

	collect()
	{
		if(this.collectionFlashing){
			clearInterval(this.collectionInterval);
			let collected;
			this.collectables.map((c)=>{
				if(document.getElementById(c.domID).classList.contains('collection-background')){collected=c;}
			});
			this.collected(collected);
		}
		else
		{
			let availableForCollection = [];
			this.collectables.map((c)=>{
				this[c.variable] > 0 && availableForCollection.push(c);
			})
			if(availableForCollection.length == 0){console.log("Nothing available to collect");return;}
			this.rwxNoticeBox.setValue('Press enter again to collect highlighted item');
			let collectionCounter = 0;
			this.collectionFlashing = true;
			let csf = this.getCurrentSquare().functionToRun;
			if(csf == "mystery" || csf == "gameOver"){return;}
			this.collectionInterval = setInterval(()=>{
				if(collectionCounter==availableForCollection.length)collectionCounter=0;
				for(let [index,c] of availableForCollection.entries())
				{
					(collectionCounter==index && this[c.variable]!==0) ? document.getElementById(c.domID).classList.add('collection-background') : document.getElementById(c.domID).classList.remove('collection-background')
				}
				collectionCounter+=1;
			}, 1000)
		}
	}

	collected(what)
	{
		this.cancelCollect();
		this.hasCollected = what;
		this.rwxSlideTicker.setValue(`Collected ${what.text}`);
		this.endFeature();
	}

	continue()
	{
		return;
	}

	rollEvenOddStop()
	{
		this.rollEvenOddFlag = false;
		let total = 0;
		for(let [i,d] of this.dice.entries())
		{
			let diff = Math.round(d.rotation.x/rwxGeometry.toRadians(90));
			let remainder = diff-(Math.floor(diff/4)*4);
			let radians = rwxGeometry.toRadians(remainder*90);
			d.rotation.x = radians;
			let toCompare = new THREE.Vector3(radians, 0, 0);
			let number = Object.keys(d.rotations).filter((ok)=>{return (d.rotations[ok].x == toCompare.x && d.rotations[ok].y == toCompare.y)});
			total += parseInt(number[0]);
		}
		if((this.rollingEven && (total%2 == 0)) || (this.rollingOdd && !(total%2 == 0)))
		{
			this.rwxNoticeBox.setValue("Good Job!", true);
		}
		else
		{
			this.gameOver();
		}
		this.currentNumber = total;
		this.rollingEven = false;
		this.rollingOdd = false;
		this.rollEvenOddResetStep1 = false;
		this.rollEvenOddResetStep1Ease = false;
	}

	rollEven()
	{
		this.rwxNoticeBox.setValue("Roll Even..");
		this.cachedRotations = this.getCurrentDiceRotations();
		this.rollingEven = true;
		this.rollEvenOddFlag = true;
	}

	rollOdd()
	{
		this.rwxNoticeBox.setValue("Roll Odd..");
		this.cachedRotations = this.getCurrentDiceRotations();
		this.rollingOdd = true;
		this.rollEvenOddFlag = true;
	}

	hiloContinue()
	{
		this.rwxNoticeBox.setValue("HI / LO Continue..");
		this.ableToGambleHI = (this.currentNumber<12);
		this.ableToGambleLO = (this.currentNumber>1);
		this.hilo = true;
	}

	loseWithCashpot()
	{
		this.collected({variable: 'coinCount', text: 'Coins', fmLinkVariable:'amountOfCoins'});
	}





	//
	// *** EVENTS
	// These methods are triggered by a user interaction on the page (distributed by the core)
	//

	rollDiceEvent(type)
	{
		if(this.collectionFlashing){this.cancelCollect();}
		if(this.diceRolled || this.stoppaRunning || this.multiAdding || this.hasNumberPicker || this.hasNumberPicker || this.hasMystery || this.hasBonus || this.hasMystery || this.stoppaOrTaxiRunning || this.endFeatureFlag || this.featureBoardInitAnimationFlag || this.rollEvenOddFlag){return;}
		this.diceRolled=true;
		this.prevRotations = this.getCurrentDiceRotations();
		this.rotationData = this.generateRandomRotations();

		let currentNumber = this.gambleNumber ? this.gambleNumber : this.currentNumber;
		if(type=="GAMBLEHI")
		{
			this.rwxNoticeBox.setValue("Going Higher", true);
			this.diceGambled = true;
			if(currentNumber == 1)
			{
				this.gambleNumber = rwxMath.randomInt(2,12);
				this.diceGambleWon = true;
			}
			else
			{
				this.diceGambleWon = (rwxMath.randomInt(0, this.diceGambleWinProbability) == 0)
				this.gambleNumber = this.diceGambleWon ? rwxMath.randomInt(currentNumber+1, 12) : rwxMath.randomInt(1,currentNumber-1);
			}
		}
		else if(type=="GAMBLELO")
		{
			this.rwxNoticeBox.setValue("Going Lower", true);
			this.diceGambled = true;
			if(currentNumber == 12)
			{
				this.gambleNumber = rwxMath.randomInt(1,11);
				this.diceGambleWon = true;
			}
			else
			{
				this.diceGambleWon = (rwxMath.randomInt(0, this.diceGambleWinProbability) == 0)
				this.gambleNumber = this.diceGambleWon ? rwxMath.randomInt(1,currentNumber-1) : rwxMath.randomInt(currentNumber+1, 12);
			}
		}
		else if(type=="ROLL")
		{
			this.diceGambled = false;
			this.prevNumberIndex = this.currentNumberIndex;
			this.gambleNumber = false;
			this.currentNumber = rwxMath.randomInt(1, 12);
		}
		currentNumber = this.gambleNumber ? this.gambleNumber : this.currentNumber;
		this.diceLayout = this.getDiceLayout(currentNumber);
		this.rollDiceFlag = true;
	}





	//
	// *** HELPERS / GETTERS
	// *** These are helper functions specific to this class for doing quick, repeatable things or retrieving quick pieces of information from elsewhere within the app
	//

	addCubeFaceGroups(geometry) // splits geometry into groups allowing for first material index to be background color and other 6 material indexes to be seperate textures
	{
		geometry.clearGroups();
		geometry.addGroup(0, Infinity, 0);
		for(let cs=0;cs<6;cs++)
		{
			geometry.addGroup(cs*6, 6, cs+1);
		}
	}

	setPointLightToCube(index)
	{
		let cubes = this.getCurrentBoardCubes();
		cubes[index].cube.getWorldPosition(this.cubeLightPosition);
		this.cubeLight.position.set(this.cubeLightPosition.x-5,this.cubeLightPosition.y,this.cubeLightPosition.z);
	}

	hasGame()
	{
		return this.numberOfIcons == this.maxNumberOfIcons;
	}

	hasExtraLife()
	{
		return this.numberOfHearts == this.maxNumberOfIcons;
	}

	getCurrentDiceRotations()
	{
		return this.dice.map((d)=>{return new THREE.Vector3(d.rotation.x, d.rotation.y, d.rotation.z)});
	}

	getCurrentNumberOfSquares()
	{
		return this.currentBoard == "outer" ? this.numberOfOuterBoardSquares : this.numberOfInnerBoardSquares;
	}

	getCurrentBoardCubes()
	{
		return this.currentBoard == "outer" ? this.outerCubes : this.innerCubes;
	}

	getDiceLayout(num)
	{
		return this.diceLayouts[num][rwxMath.randomInt(0,this.diceLayouts[num].length-1)];
	}

	getCurrentSquare()
	{
		return this.getCurrentBoardCubes()[this.currentNumberIndex];
	}

	generateRandomRotations()
	{
		let spinX=0, spinY=0, spinZ=0;
		return this.dice.map((d)=> {
			let x = rwxMath.randomInt(0,1);
			let y = rwxMath.randomInt(0,1);
			let z = (!x && !y) ? 1 : rwxMath.randomInt(0,1);
			if(x){spinX = rwxMath.randomInt(0,1) == 0 ? this.diceSpinLength : -this.diceSpinLength}
			if(y){spinY = rwxMath.randomInt(0,1) == 0 ? this.diceSpinLength : -this.diceSpinLength}
			if(z){spinZ = rwxMath.randomInt(0,1) == 0 ? this.diceSpinLength : -this.diceSpinLength}
			return {x,y,z,spinX,spinY,spinZ}
		})
	}





	//
	// *** ANIMATIONS
	// *** These are all called from the animation loop when a certain variable is set
	//

	rollEvenOdd()
	{
		if(!this.rollEvenOddResetStep1)
		{
			let val = rwxAnimate.getEasingValue('easingValueRollEvenOdd', 'linear', 500, () => { this.rollEvenOddResetStep1 = true; });
			for(let [index, d] of this.dice.entries())
			{
				d.rotation.set(rwxAnimate.fromToCalc(this.cachedRotations[index].x, 0, val), rwxAnimate.fromToCalc(this.cachedRotations[index].y, 0, val), rwxAnimate.fromToCalc(this.cachedRotations[index].z, 0, val));
			}
		}
		else
		{
			for(let [index,d] of this.dice.entries())
			{
				d.rotation.x += index==0 ? this.rollEvenOddDegrees : -this.rollEvenOddDegrees;
			}
		}	
	}

	rotateFeatureBoardCube()
	{
		if(!this.rotateFeatureBoardCubeStep1)
		{
			this.randomFeatureBoardCubeRotationMesh.rotation.x = rwxAnimate.fromTo(0, rwxGeometry.toRadians(90), 'fromToRotateFeatureBoardCube', 'easeInOutQuart', 500, () => { this.rotateFeatureBoardCubeStep1 = true; });
		}
		else
		{
			this.rotateFeatureBoardCubeStep1 = false;
			this.rotateFeatureBoardCubeStep1Ease = false;
			this.rotateFeatureBoardCubeFlag = false;
		}
	}

	moveIn()
	{
		if(!this.moveInStep1)
		{
			let val = rwxAnimate.getEasingValue('easingValueMoveIn', 'easeInOutCubic', 1000, () => { this.moveInStep1 = true; });
			this.camera.position.x = rwxAnimate.fromToCalc(this.featureCameraPositionDefault.x, (this.featureCameraPositionDefault.x + this.innerBoardDistance), val);
			this.fbPersistentZGroup.position.z = rwxAnimate.fromToCalc(0, -this.innerBoardDistance, val);
			this.innerCubes.map((ic)=>{ic.cube.material.map(m=>m.opacity = rwxAnimate.fromToCalc(this.innerBoardInactiveOpacity, 1, val))});
		}
		else
		{
			this.moveInStep1 = false;
			this.moveInStep1Ease = false
			this.moveInFlag = false;
		}
	}

	moveOut()
	{
		if(!this.moveOutStep1)
		{
			let val = rwxAnimate.getEasingValue('easingValueMoveOut', 'easeInOutCubic', 1000, () => { this.moveOutStep1 = true; });
			this.camera.position.x = rwxAnimate.fromToCalc((this.featureCameraPositionDefault.x + this.innerBoardDistance), this.featureCameraPositionDefault.x, val);
			this.fbPersistentZGroup.position.z = rwxAnimate.fromToCalc(-this.innerBoardDistance, 0, val);
			this.innerCubes.map((ic)=>{ic.cube.material.map(m=>m.opacity = rwxAnimate.fromToCalc(1, this.innerBoardInactiveOpacity, val))});
		}
		else
		{
			this.moveOutStep1 = false;
			this.moveOutStep1Ease = false;
			this.moveOutFlag = false;
		}
	}

	rollDice()
	{
		if(!this.rollDiceStep1)
		{
			let val = rwxAnimate.getEasingValue('easingValueRollDice', 'easeOutQuad', this.diceSpinDuration, () => { this.rollDiceStep1 = true; });
			for(let [index,d] of this.dice.entries())
			{
				d.rotation.set(rwxAnimate.fromToCalc((this.prevRotations[index].x - this.rotationData[index].spinX), (this.rotationData[index].spinX + d.rotations[this.diceLayout[index]].x), val), rwxAnimate.fromToCalc((this.prevRotations[index].y - this.rotationData[index].spinY), (this.rotationData[index].spinY + d.rotations[this.diceLayout[index]].y), val), rwxAnimate.fromToCalc((this.prevRotations[index].z - this.rotationData[index].spinZ), (this.rotationData[index].spinZ + d.rotations[this.diceLayout[index]].z), val));
			}
		}
		else
		{
			this.diceHasRolled();
			this.rollDiceStep1 = false;
			this.rollDiceStep1Ease = false;
			this.rollDiceFlag = false;
		}
	}

	featureBoardInitAnimation()
	{
		for(let [i,cube] of this.outerCubes.entries())
		{
			if(this.timeoutPerCubeCounter <= (this.initialAnimationConfigurables.timeoutPerCube*i)){continue;}
			else
			{
        if(!this[`featureBoardInitialAnimationStep1-${i}`])
        {
        	let val = rwxAnimate.getEasingValue(`easingValueFeatureBoardInitAnimation-${i}`, 'easeOutQuart', 500, () => { this[`featureBoardInitialAnimationStep1-${i}`] = true; });
					cube.cube.rotation.x = rwxAnimate.fromToCalc(rwxGeometry.toRadians(-90), rwxGeometry.toRadians(0), val);
					cube.cube.material.map(c=> c.opacity= rwxAnimate.fromToCalc(0, 1, val));
        }
        else
        {
          if(i == (this.outerCubes.length-1)){
          	if(!this.featureBoardDevelop)
          	{
							this.showDOMelements(true, this.fbDOMElements.map(de=>de.domID));
							this.stoppa();
          	}
          	for(let i=0;i<this.outerCubes.length;i++)
				    {
				    	this[`featureBoardInitialAnimationStep1-${i}`] = false;
				    	this[`featureBoardInitialAnimationStep1-${i}Ease`] = false;
				    }
						this.featureBoardInitAnimationFlag = false;
          }            
        }
			}
		}	
		this.timeoutPerCubeCounter +=1;
	}
}