import * as THREE from 'three';

import { FeatureBoard } from './featureBoard.js';

import rwxMath from 'roseworx/js/helpers/rwxMathHelpers';
import rwxMisc from 'roseworx/js/helpers/rwxMiscHelpers';
import rwxGeometry from 'roseworx/js/helpers/rwxGeometryHelpers';
import rwxAnimate from 'roseworx/js/helpers/rwxAnimateHelpers';

export class FruitMachine extends FeatureBoard {
  constructor()
  {
    super();
    this.fruitMachineDevelop = false;
  }

  //
  // *** CREATORS
  // *** These methods are responsible for creating the objects which render on the actual page
  //
  
  createFruitMachine()
  {
    this.createReels();
    this.enableNumbers && this.createNumberReel();
  }

  createButton(length, x, r)
  {
    let width = 6;
    let buttonShape = new THREE.Shape();
    buttonShape.moveTo( 0,0 );
    buttonShape.lineTo( 0, width );
    buttonShape.lineTo( length, width );
    buttonShape.lineTo( length, 0 );
    buttonShape.lineTo( 0, 0 );

    let buttonExtrudeParameters = {
      steps: 1,
      depth: 0.5,
      bevelEnabled: true,
      bevelThickness: 0.5,
      bevelSize: 0.8,
      bevelOffset: 0,
      bevelSegments: 1
    };

    let buttonGeometry = new THREE.ExtrudeBufferGeometry( buttonShape, buttonExtrudeParameters );
    let buttonMaterial = new THREE.MeshStandardMaterial( { color: 0xffffff, transparent: true, opacity:0.5 } );
    let buttonMesh = new THREE.Mesh( buttonGeometry, buttonMaterial ) ;
    let y = -(this.reelSize - length - 3);
    this.buttonYPosition = y;

    let startVectors = new THREE.Vector3(x - length/2, -100, this.reelSize)

    buttonMesh.position.set(startVectors.x, startVectors.y, startVectors.z);
    this.fruitMachineInitAnimationData.buttons.push({button: buttonMesh, starty:startVectors.y, finishy:y});

    buttonMesh.rotation.x = rwxGeometry.toRadians(90);
    buttonMesh.reelIndex = r;
    this.reelControlButtons.push(buttonMesh);
    this.scene.add(buttonMesh);
    r==0 && this.checkAndApplyHelperTip(buttonMesh, "helper-tip-buttons");
  }

  createNumberReel()
  {
    let x,z,nextX,nextZ,numberReelFaceGeometry,numberReelFaceMaterial,numberReelFaceMesh,numberReelFaceGroup,numberTextureGeometry,numberTextureMaterial,numberTextureMesh;
    let clone = rwxMisc.safeCloneArrayOfObjects(this.numbers);
    clone = [{
        value:0,
        texture: new THREE.TextureLoader().load( require("./fruitMachineAssets/zero.png") )
    }, ...clone];
    clone.reverse();
    const numberReelSize = this.reelSize/4;
    const numberReelWidth = this.reelWidth/4;
    this.numberReelOneRotation = (360/clone.length);
    const startRotation = this.numberReelOneRotation/2;
    const numberReelGroup = new THREE.Group();
    const numberReelFaceSideLength = ((2*numberReelSize) * Math.sin(Math.PI/clone.length));
    const numberReelFaceAngle = 2 * Math.PI / clone.length;
    const numberReelEdgeGeometry = new THREE.CylinderBufferGeometry(numberReelSize, numberReelSize, 0.3, clone.length);
    const numberReelEdgeMaterial = new THREE.MeshStandardMaterial({color: 0xffffff, transparent:true, opacity:0.2, flatShading: true});
    const numberReelEdgeMesh = new THREE.Mesh( numberReelEdgeGeometry, numberReelEdgeMaterial );
    const numberReelEdgeMesh2 = numberReelEdgeMesh.clone();
    numberReelEdgeMesh.position.y = -(numberReelWidth/2);
    numberReelEdgeMesh2.position.y = (numberReelWidth/2);
    for(let nr=0;nr<clone.length;nr++)
    {
      let reelFaceColor = nr == clone.length-1 ? this.numberHighlightColor : 0xffffff;
      if(nr == this.numbersBonus){reelFaceColor = this.numberBonusColor;}
      if(nr == 0){reelFaceColor = this.numberFeatureStartColor;}
      numberReelFaceGroup = new THREE.Group();
      x = numberReelSize * Math.sin(nr * numberReelFaceAngle);
      z = numberReelSize * Math.cos(nr * numberReelFaceAngle);
      nextX = numberReelSize * Math.sin((nr+1) * numberReelFaceAngle);
      nextZ = numberReelSize * Math.cos((nr+1) * numberReelFaceAngle);

      numberReelFaceGeometry = new THREE.BoxBufferGeometry( numberReelFaceSideLength, numberReelWidth, 0.1 );
      numberReelFaceMaterial = new THREE.MeshStandardMaterial({color: reelFaceColor, transparent:true, opacity:0.6, flatShading: true});
      numberReelFaceMaterial.polygonOffset = true;
      numberReelFaceMaterial.polygonOffsetFactor = 0.1;
      numberReelFaceMesh = new THREE.Mesh( numberReelFaceGeometry, numberReelFaceMaterial );
      numberReelFaceMesh.renderOrder = 0;
      numberReelFaceGroup.add(numberReelFaceMesh);

      if(clone[nr].texture)
      {
        numberTextureGeometry = new THREE.BoxBufferGeometry( numberReelFaceSideLength/2, numberReelWidth/2, 0.1 );
        numberTextureMaterial = new THREE.MeshStandardMaterial({transparent: true, map:clone[nr].texture});
        numberTextureMesh = new THREE.Mesh( numberTextureGeometry, numberTextureMaterial );
        numberTextureMesh.renderOrder = 1;
        numberReelFaceGroup.add(numberTextureMesh);
      }

      numberReelFaceGroup.position.x = (x + nextX)/2;
      numberReelFaceGroup.position.z = (z + nextZ)/2;
      numberReelFaceGroup.rotation.y = rwxGeometry.toRadians(startRotation + (nr*this.numberReelOneRotation));
      this.numberReelFaces.push(numberReelFaceMesh);
      numberReelGroup.add(numberReelFaceGroup);
    }

    let finishVectors = new THREE.Vector3(8, 0, 32);
    let startVectors = new THREE.Vector3(8, -100, 50);

    numberReelGroup.position.set(startVectors.x, startVectors.y, startVectors.z);
    this.fruitMachineInitAnimationData.numberReel.push({reel: numberReelGroup, start: startVectors, finish: finishVectors});

    numberReelGroup.rotation.x = rwxGeometry.toRadians(-47.5);
    this.numberReel = numberReelGroup;
    this.scene.add(numberReelGroup);
    this.checkAndApplyHelperTip(numberReelGroup, 'helper-tip-number-reel');
  }

  createReels()
  {
    let reelNumberGeometry, reelNumberMaterial, reelNumberMesh, reelFaceGeometry, reelFaceMaterial, reelFaceMesh, reelFaceEdge, reelFaceOutline, reelFruitGeometry,
        reelFruitMaterial, reelFruitMesh, reelEdgeGeometry, reelEdgeMaterial, reelEdgeMesh, reelEdgeMesh2, reelEdgeEdge, reelEdgeOutline, reelEdgeOutline2, reelGroup,
        reelFaceGroup, x, z, nextX, nextZ, fx, fz, fnextX, fnextZ, startVectors, finishVectors,
        reelFaceSideLength = ((2*this.reelSize) * Math.sin(Math.PI/this.numberOfFruits)),
        reelFaceAngle = 2 * Math.PI / this.numberOfFruits,
        startRotation = this.oneRotation/2,
        xPosition = -(this.numberOfReels-1)/2 * (this.reelWidth+this.reelSpacing),
        bonusReelPosition = rwxMath.randomInt(0,this.numberOfReels-1);

    for(let r=0;r<this.numberOfReels;r++)
    {
      reelGroup = new THREE.Group();
      let shuffleArray = rwxMisc.safeCloneArrayOfObjects(this.fruits);
      if(this.enableNumbers)
      {
        for(let rn=0;rn<rwxMath.randomInt(0, (this.numberOfFruits-1)/2);rn++)
        {
          shuffleArray[rn].number = this.numbers[rwxMath.randomInt(0, this.numberMaximumOnReels-1)];
        }
      }
      if(this.enableBonus)
      {
        if(r == bonusReelPosition)
        {
          //Add bonus
          shuffleArray.push({
            name: "bonus",
            texture: new THREE.TextureLoader().load( require("./fruitMachineAssets/bonus.png") )
          })
        }
        else
        {
          //Add random fruit
          let randomFruit = rwxMisc.safeCloneObject(this.fruits[rwxMath.randomInt(0,this.fruits.length-1)])
          shuffleArray.push(randomFruit);
        }
      }

      rwxMisc.shuffleArray(shuffleArray);
      this.reelFruits.push(shuffleArray);
      for (let i=0;i<this.numberOfFruits;i++)
      {
        reelFaceGroup = new THREE.Group();
        x = this.reelSize * Math.sin(i * reelFaceAngle);
        z = this.reelSize * Math.cos(i * reelFaceAngle);
        nextX = this.reelSize * Math.sin((i+1) * reelFaceAngle);
        nextZ = this.reelSize * Math.cos((i+1) * reelFaceAngle);

        fx = this.initialAnimationConfigurables.reelFaceOffset * Math.sin(i * reelFaceAngle);
        fz = this.initialAnimationConfigurables.reelFaceOffset * Math.cos(i * reelFaceAngle);
        fnextX = this.initialAnimationConfigurables.reelFaceOffset * Math.sin((i+1) * reelFaceAngle);
        fnextZ = this.initialAnimationConfigurables.reelFaceOffset * Math.cos((i+1) * reelFaceAngle);

        reelFaceGeometry = new THREE.BoxBufferGeometry( reelFaceSideLength, this.reelWidth, 0.1 );
        reelFaceMaterial = new THREE.MeshStandardMaterial({color: 0xffffff, transparent:true, opacity:0.5, flatShading: true});
        reelFaceMaterial.polygonOffset = true;
        reelFaceMaterial.polygonOffsetFactor = 0.1;
        reelFaceMesh = new THREE.Mesh( reelFaceGeometry, reelFaceMaterial );
        reelFaceMesh.renderOrder = 0;
        reelFaceGroup.add(reelFaceMesh);

        if(shuffleArray[i] && shuffleArray[i].texture)
        {
          let sizeDivide = (shuffleArray[i].name == "wild" || shuffleArray[i].name == "bonus") ? 1.2 : 2; // wild and bonus word is bigger to be seen / readable
          reelFruitGeometry = new THREE.BoxBufferGeometry( reelFaceSideLength/sizeDivide, this.reelWidth/sizeDivide, 0.1 );
          reelFruitMaterial = new THREE.MeshStandardMaterial({transparent: true, map:shuffleArray[i].texture});
          reelFruitMesh = new THREE.Mesh( reelFruitGeometry, reelFruitMaterial );
          reelFruitMesh.rotation.z = rwxGeometry.toRadians(-90); 
          reelFruitMesh.renderOrder = 1;
          reelFaceGroup.add(reelFruitMesh);
        }

        if(shuffleArray[i] && shuffleArray[i].number !== undefined)
        {
          reelNumberGeometry = new THREE.BoxBufferGeometry( 1.5, 1.5, 0.1 );
          reelNumberMaterial = new THREE.MeshStandardMaterial({transparent:true, map:shuffleArray[i].number.texture});
          reelNumberMesh = new THREE.Mesh( reelNumberGeometry, reelNumberMaterial );
          reelNumberMesh.renderOrder = 2;
          reelNumberMesh.position.y = -(this.reelWidth/2-1.5);
          reelNumberMesh.position.x = -(reelFaceSideLength/2-1.5);
          reelNumberMesh.rotation.z = rwxGeometry.toRadians(-90);
          reelFaceGroup.add(reelNumberMesh);
        }

        reelFaceEdge = new THREE.EdgesGeometry(reelFaceGeometry);
        reelFaceOutline = new THREE.LineSegments(reelFaceEdge, new THREE.LineBasicMaterial({ color: 0xffffff }));        
        reelFaceGroup.add(reelFaceOutline);

        startVectors = new THREE.Vector3((fx + fnextX)/2, 0, (fz + fnextZ)/2);
        finishVectors = new THREE.Vector3((x + nextX)/2, 0, (z + nextZ)/2);
        reelFaceGroup.position.set(startVectors.x, startVectors.y, startVectors.z);
        
        this.fruitMachineInitAnimationData.reelFaces.push({start: startVectors, finish: finishVectors, face: reelFaceGroup, timeout:i*10, rotation:rwxGeometry.toRadians(startRotation + (i*this.oneRotation))});

        reelGroup.add(reelFaceGroup);
      }

      reelEdgeGeometry = new THREE.CylinderBufferGeometry(this.reelSize, this.reelSize, 0.3, this.numberOfFruits);
      reelEdgeMaterial = new THREE.MeshStandardMaterial({color: 0xffffff, transparent:true, opacity:0.2, flatShading: true});
      reelEdgeMesh = new THREE.Mesh( reelEdgeGeometry, reelEdgeMaterial );
      reelEdgeMesh2 = reelEdgeMesh.clone();

      reelEdgeEdge = new THREE.EdgesGeometry(reelEdgeGeometry);
      reelEdgeOutline = new THREE.LineSegments(reelEdgeEdge, new THREE.LineBasicMaterial({ color: 0xffffff }));
      reelEdgeOutline2 = reelEdgeOutline.clone();

      reelEdgeMesh.position.y = -(this.reelWidth/2)-this.initialAnimationConfigurables.reelEdgeOffset;
      reelEdgeOutline.position.y = -(this.reelWidth/2)-this.initialAnimationConfigurables.reelEdgeOffset;

      this.fruitMachineInitAnimationData.reelEdges.push({edge: reelEdgeMesh, outline:reelEdgeOutline, finishy: -(this.reelWidth/2), starty: -(this.reelWidth/2)-this.initialAnimationConfigurables.reelEdgeOffset});

      reelGroup.add( reelEdgeMesh );
      reelGroup.add( reelEdgeOutline );

      reelEdgeMesh2.position.y = (this.reelWidth/2)+this.initialAnimationConfigurables.reelEdgeOffset;
      reelEdgeOutline2.position.y = (this.reelWidth/2)+this.initialAnimationConfigurables.reelEdgeOffset;

      this.fruitMachineInitAnimationData.reelEdges.push({edge: reelEdgeMesh2, outline:reelEdgeOutline2, finishy: (this.reelWidth/2), starty: (this.reelWidth/2)+this.initialAnimationConfigurables.reelEdgeOffset});

      reelGroup.add( reelEdgeMesh2 ); 
      reelGroup.add( reelEdgeOutline2 );
      
      reelGroup.position.y = this.reelYPos;
      reelGroup.position.x = xPosition;
      reelGroup.rotation.z = rwxGeometry.toRadians(90);
      reelGroup.rotation.x = this.setReelRotation(r, 'spin');
      this.reels.push(reelGroup);
      this.scene.add(reelGroup);

      this.createButton(reelFaceSideLength, xPosition, r);

      xPosition += (this.reelWidth + this.reelSpacing);
      r==0 && this.checkAndApplyHelperTip(reelGroup, 'helper-tip-reels');
    }
  }





  //
  // *** MISCELLANEOUS
  //
  //

  reelsHaveNudged()
  {
    if(!this.hasNudges() || this.hasWonFlag || this.hasBonus || this.hasFeature)
    {
      this.amountOfNudges = 0;
      this.nudgeReelCounter = new Array(this.numberOfReels).fill(0);
      this.reverseNudge = false;
      console.log("NO MORE NUDGES");
    }

    this.enableNumbers && this.updateNumberTrail();
    this.hasFeature && this.toFeatureBoard();
    if(!this.freePlay && this.hasWonFlag)
    {
      this.amountOfCoins += this.winValue;
    }
    (this.enableBonus && this.hasBonus) && this.setTombola(this.fruitMachineBonuses, 'hasBonus'); 
  }

  reelsHaveSpun()
  {
    if(this.streakRunning)
    {
      this.hasWonFlag = false;
      if(this.streakFruitIndex == this.streakFruits.length-1)
      {
        this.rwxNoticeBox.setValue("That's your lot!", true);
        this.streakRunning = false;
        this.reelsHaveSpunCallback = true;
        return;
      }
      else
      {
        this.spinReelsFlag = false;
        this.streakFruitIndex+=1;
        this.determineWin();
        if(!this.freePlay)
        {
          this.amountOfCoins += this.winValue;
          this.rwxNoticeBox.setValue(`Won ${this.winValue} coins!`, true);
        }       
        this.spinReelsEvent(this.streakFruits[this.streakFruitIndex]);
      }
      return;
    }

    this.enableNumbers && this.updateNumberTrail();
    this.hasFeature && this.toFeatureBoard();
    if(!this.freePlay && this.hasWonFlag)
    {
      this.amountOfCoins += this.winValue;
      this.rwxNoticeBox.setValue(`Won ${this.winValue} coins!`, true);
    }

    (this.enableBonus && this.hasBonus) && this.setTombola(this.fruitMachineBonuses, 'hasBonus');

    if(!this.hasWonFlag && !this.hasBonus && !this.hasFeature)
    {
      this.assignHolds();
      if(!this.hasHolds){
        this.assignNudges();
      }
    }

    this.reelHeldCounter = new Array(this.numberOfReels).fill(0);
  }

  setReelRotation(reelIndex, type)
  {
    let ri;
    let rr;
    if(type == 'spin' || type == 'randomWin')
    {
      // FOR DEBUGGING - SET RR & RI TO this.reelFruits[reelIndex].findIndex(d=>d.name=="FRUITNAME"); to get the fruit you want and comment out rest
      if(this.reelHeldCounter[reelIndex] === 1) // reel is held
      {
        ri = this.currentFruits[reelIndex];
        rr = ri;
      }
      else
      {
        let sameAsPrev = type=='randomWin' ? 0 : rwxMath.randomInt(0, this.reelWinProbability);
        let prevName;
        if(reelIndex !=0 ) {prevName = this.reelFruits[reelIndex-1][this.currentFruits[reelIndex-1]].name;}
        if(sameAsPrev == 0 && reelIndex != 0 && prevName != "bonus")
        {
          for(let [index,fruit] of this.reelFruits[reelIndex].entries())
          {
            if(fruit.name == prevName)
            {
              ri = index;
              break;
            }
          }
        }
        else
        {
          ri = rwxMath.randomInt(0, this.numberOfFruits-1);
        }
        rr = ri;
      }
    }
    else if (type == 'nudge')
    {
      /*  
          If you nudge and the rotation reaches the number of fruits on the reel, we need ot reset the currentFruitIndex
          back to 0 as that will be the index of the current fruit. However if you then calculate radians from 0 when 
          nudging, it will spin all the way back round to 0 instead of nudging forward. We need a nudgeCounter to determine
          if the index goes past the numberOfFruits and then continue on from that to calculate the radius.
       */
      if(this.reverseNudge)
      {
        ri = this.currentFruits[reelIndex] == 0 ? this.numberOfFruits-1 : this.currentFruits[reelIndex] - 1;

        if(this.nudgeReelCounter[reelIndex] < 0)
        {
          this.nudgeReelCounter[reelIndex] -= 1;
          rr = this.nudgeReelCounter[reelIndex];
        }
        else if(this.currentFruits[reelIndex] == 0)
        {
          rr = -1;
          this.nudgeReelCounter[reelIndex] = rr;
        }
        else
        {
          rr = ri;
        }
      }
      else
      {
        ri = this.currentFruits[reelIndex] == this.numberOfFruits-1 ? 0 : this.currentFruits[reelIndex] + 1;
        if(this.nudgeReelCounter[reelIndex] > this.numberOfFruits-1)
        {
          this.nudgeReelCounter[reelIndex] += 1;
          rr = this.nudgeReelCounter[reelIndex];
        }
        else if(this.currentFruits[reelIndex] == this.numberOfFruits-1)
        {
          rr = this.numberOfFruits;
          this.nudgeReelCounter[reelIndex] = rr;
        }
        else
        {
          rr = ri;
        }
      }
    }
    else //type will be name of fruit which needs to win
    {
      ri = this.reelFruits[reelIndex].findIndex(d=>d.name==type)
      rr = ri;
    }

    this.currentFruits[reelIndex] = ri;
    let radians = rwxGeometry.toRadians(this.oneRotation * rr);
    //Set the current spin values to equal the next spin values which are actually at this point the current ones - before it sets the new spin vals
    this.currentSpinValues[reelIndex] = this.currentSpinValues.length > 0 ? this.nextSpinValues[reelIndex] : radians;
    this.nextSpinValues[reelIndex] = radians;
    return radians;
  }





  //
  // *** HELPERS / GETTERS
  // *** These are helper functions specific to this class for doing quick, repeatable things or retrieving quick pieces of information from elsewhere within the app
  //

  calculateStreakWinnersArray(type)
  {
    let fruitsArray = [];
    let moneyArray = [];
    let monetaryValue = this.fruits.map((f)=>f.coinValue && moneyArray.push(f.coinValue));
    moneyArray.sort((a, b)=> a-b);

    let streakLength;
    if(type=='streak'){streakLength = rwxMath.randomInt(3,7);}
    else if(type=='super'){streakLength = rwxMath.randomInt(4,8);}
    else if(type=='mega'){streakLength = rwxMath.randomInt(5,10);}

    let streakArrBounds = Math.floor(moneyArray.length * (this.streakBounds/100));
    let superStreakArrBounds = Math.floor(moneyArray.length * (this.superStreakBounds/100));
    let megaStreakArrBounds = Math.floor(moneyArray.length * (this.megaStreakBounds/100));
    let maxCoinValue;
    if(type=='streak'){maxCoinValue = streakArrBounds;}
    else if(type=='super'){maxCoinValue = superStreakArrBounds;}
    else if(type=='mega'){maxCoinValue = megaStreakArrBounds;}

    for(let i=0;i<streakLength;i++)
    {
      let coinValue = moneyArray[rwxMath.randomInt(0, maxCoinValue)];
      let fruitName = this.fruits.filter(f=>f.coinValue==coinValue)[0].name;
      fruitsArray.push(fruitName);
    }
    return fruitsArray;
  }

  resetNumberTrail()
  {
    this.reelNumbers = 0;
    this.numberTrailHeld = false;
    this.numberTrailHeldFromBonus = false;
  }

  updateNumberTrail()
  {
    this.spinNumberReelStep1 = false;
    this.spinNumberReelStep1Ease = false;
    this.numberReelPreviousRotation = this.numberReelPreviousRotation=== undefined ? 0 : this.numberReelCurrentRotation;
    let rotation = this.reelNumbers >= this.numberOfNumbers ? this.numberOfNumbers : this.reelNumbers;
    this.numberReelCurrentRotation = rwxGeometry.toRadians(rotation*this.numberReelOneRotation);
    if(this.numberReelPreviousRotation == this.numberReelCurrentRotation)return;
    this.numberReelSpinFlag = true;
    let color = this.numberTrailHeld || this.numberTrailHeldFromBonus ? this.numberTrailHeldColor : 0xffffff;
    for(let [index,f] of this.numberReelFaces.entries())
    {
      f.material.color.set(color);
      //numberReelFaces is reversed remember
      if(index==0){f.material.color.set(this.numberFeatureStartColor);}
      else if(index==(this.numberReelFaces.length-1-this.numbersBonus)){f.material.color.set(this.numberBonusColor);}
      else if(index==(this.numberReelFaces.length-1-rotation)){f.material.color.set(this.numberHighlightColor);};
    }
    this.numberTrailHeld && this.rwxNoticeBox.setValue('Number trail held', true);
  }

  hasNudges()
  {
    return this.amountOfNudges>0;
  }

  hasReelStrikes()
  {
    return this.amountOfReelStrikes>0;
  }

  assignNudges()
  {
    let p = rwxMath.randomInt(0,this.nudgeProbability);
    if(p>0){return;}
    this.amountOfNudges = rwxMath.randomInt(1, 4);
  }

  assignHolds()
  {
    // this.hasHolds = true;
    // return;
    let p = rwxMath.randomInt(0,this.holdProbability);
    if(p>0){return;}
    this.numberTrailHeld = true;
    this.hasHolds = true;
  }





  //
  // *** Functional
  // *** These methods actually do the things which happen during the game
  //
  streak(strength)
  {
    this.streakRunning = true;
    this.streakFruitIndex = 0;
    let type;
    if(strength<=(this.streakBounds/10))type='streak';
    else if(strength>(this.streakBounds/10) && strength<=(this.superStreakBounds/10))type='super';
    else if(strength>(this.superStreakBounds/10) <=(this.megaStreakBounds/10))type='mega';
    this.streakFruits = this.calculateStreakWinnersArray(type);
    this.spinReelsEvent(this.streakFruits[this.streakFruitIndex]);
  }

  reelStrikes()
  {
    this.reelStrikeFlag = true;
  }

  bonusStreak()
  {
    this.streak(5);
  }

  bonusWinSpin()
  {
    this.spinReelsEvent('randomWin');
  }

  bonusStoppaReel()
  {
    this.cachedRotations = this.reels.map(a => a.rotation.x);
    this.stoppaReelIndex = 0;
    this.stoppaReelPause = false;
    this.bonusStoppaReelSpeedDegrees = rwxGeometry.toRadians(this.bonusStoppaReelSpeed);
    this.stoppaReelFlag = true;
  }

  bonusNumbersInView()
  {
    this.trailHeld = true;
    this.determineNumbers(true, "all");
    this.updateNumberTrail();
    if(this.hasFeature)
    {
      this.toFeatureBoard();
    }
    else if(this.hasBonus)
    {
      this.setTombola(this.fruitMachineBonuses, 'hasBonus');
    }
  }

  bonusOneReelStrike()
  {
    this.amountOfReelStrikes = 1;
    this.reelStrikes();
  }

  bonusTwoReelStrikes()
  {
    this.amountOfReelStrikes = 3;
    this.reelStrikes();
  }

  bonusTwoNudges()
  {
    this.amountOfNudges = 2;
    this.flashButtonsFlag = true;
  }

  bonusFourNudges()
  {
    this.amountOfNudges = 4;
    this.flashButtonsFlag = true;
  }

  bonusThreeReverseNudges()
  {
    this.nudgeReelCounter = new Array(this.numberOfReels).fill(0);
    this.reverseNudge = true;
    this.amountOfNudges = 3;
    this.flashButtonsFlag = true;
  }

  bonusFourReverseNudges()
  {
    this.nudgeReelCounter = new Array(this.numberOfReels).fill(0);
    this.reverseNudge = true;
    this.amountOfNudges = 4;
    this.flashButtonsFlag = true;
  }

  bonusUnlucky()
  {
    return;
  }





  //
  // *** DETERMINATIONS
  // *** These methods process information and determine conditions
  //

  determineBonus(reelIndex="all") // for nudges just check the reel being nudged not all
  {
    let hasBonus;
    if(reelIndex == "all")
    {
      hasBonus = this.currentFruits.some((f, i)=> {return this.reelFruits[i][f].name == "bonus"});
    }
    else
    {
      hasBonus = this.reelFruits[reelIndex][this.currentFruits[reelIndex]].name == "bonus";
    }
    return hasBonus;
  }

  determineNumbers(numbersInView=false, reelIndex)
  {
    let spinNumbersTotal = 0;
    this.currentFruits.map((cf,i)=>{
      if(this.reelFruits[i][cf].number !== undefined){
        if(this.numberTrailHeldFromBonus && reelIndex!=i && reelIndex!='all')return;
        if(this.numberTrailHeld && reelIndex!=i && reelIndex!='all')return;
        spinNumbersTotal += this.reelFruits[i][cf].number.value;
      }
      if(numbersInView)
      {
        let before = cf==0 ? this.numberOfFruits-1 : cf-1;
        let after = cf==this.numberOfFruits-1 ? 0 : cf+1;
        if(this.reelFruits[i][before].number !== undefined){
          spinNumbersTotal += this.reelFruits[i][before].number.value;
        }
        if(this.reelFruits[i][after].number !== undefined){
          spinNumbersTotal += this.reelFruits[i][after].number.value;
        }
      }
    });
    if(this.numberTrailHeld || this.numberTrailHeldFromBonus)
    {
      this.reelNumbers += spinNumbersTotal;
    }
    else
    {
      this.reelNumbers = spinNumbersTotal;
    }

    if(this.reelNumbers == this.numbersBonus && !this.numberTrailHeldFromBonus && !this.hasBonus)
    {
      this.hasBonus = true;
      this.numberTrailHeld = true;
      this.numberTrailHeldFromBonus = true;
    }
    if(this.reelNumbers >= this.numberOfNumbers)
    {
      this.hasBonus = false;
      this.hasFeature = true;
      return;
    }

    if(!this.numberTrailHeldFromBonus && reelIndex == 'all')
    {
      let p = rwxMath.randomInt(0,this.numberTrailHeldProbability);
      this.numberTrailHeld = p==0;
    }
    if(this.reelNumbers == 0)
    {
      this.numberTrailHeld = false;
    }
  }

  determineFeature()
  {
    let hasFeature = this.currentFruits.every((cf, i)=>{return (this.reelFruits[i][cf].name == "wild")});
    return hasFeature;
  }

  determineWin()
  {
    let first;
    for(let is=0;is<this.currentFruits.length;is++)
    {
      if(this.reelFruits[is][this.currentFruits[is]].name == "wild" || this.reelFruits[is][this.currentFruits[is]].name == "bonus"){continue;}
      else{first = this.reelFruits[is][this.currentFruits[is]].name; break;}
    }
    let isWin = this.currentFruits.every((cf, i)=>{return (this.reelFruits[i][cf].name == first || this.reelFruits[i][cf].name == "wild")});
    if(isWin)
    {
      this.winValue = this.fruits.filter(f=>f.name==first)[0].coinValue;
    }
    return isWin;
  }

  determineWinLine(reelIndex="all")
  {
    this.hasFeature = this.determineFeature();
    if(!this.hasFeature)
    {
      this.hasWonFlag = this.determineWin();
      if(!this.hasWonFlag)
      {
        if(this.enableBonus)
        {
          this.hasBonus = this.determineBonus(reelIndex);
        }
        if(this.enableNumbers)
        {
          this.determineNumbers(false, reelIndex);
        }
      }
    }
  }






  //
  // *** EVENTS
  // These methods are triggered by a user interaction on the page (distributed by the core)
  //

  holdReelEvent(reelIndex)
  {
    this.reelHeldCounter[reelIndex] = this.reelHeldCounter[reelIndex] == 0 ? 1 : 0;
  }

  nudgeReelEvent(reelIndex)
  {
    if(this.nudgeReelFlag || this.hasWonFlag || this.spinReelsFlag || this.hasFeatureFlag || this.hasBonus)return;
    this.amountOfNudges -= 1;
    this.reelToNudge = reelIndex;
    this.setReelRotation(this.reelToNudge, 'nudge');

    this.determineWinLine(reelIndex);

    this.nudgeReelFlag = true;

    this.reelsHaveNudged();//only take half a second isnt worth waiting for animation to complete
  }

  spinReelsEvent(win=false)
  {
    if(this.hasHolds){
      this.flashButtonsReset();
      this.hasHolds = false;
    }

    if(this.spinReelsFlag || this.hasWonFlag || this.nudgeReelFlag || this.hasNudges() || this.hasReelStrikes() || this.hasBonus || this.stoppaReelFlag || this.hasFeatureFlag || this.fruitMachineInitAnimationFlag)return;

    if(this.amountOfCoins<=0 && !this.freePlay){
      this.rwxNoticeBox.setValue("No more coins");
      console.log("BUY MORE COINS!");
      return;
    }

    this.numberTrailHeldFromBonus = false;

    if(!this.freePlay && !win)
    {
      this.amountOfCoins -= this.costPerSpin;
    }

    if(!this.numberTrailHeld && this.enableNumbers)
    {
      this.reelNumbers = 0;
      this.updateNumberTrail();
    }

    for(let r=0;r<this.numberOfReels;r++)
    {
      let type = win ? win : 'spin';
      this.setReelRotation(r, type);
    }

    this.determineWinLine();

    if(this.hasWonFlag || this.hasBonus || this.hasFeature)
    {
      this.flashButtonsReset();
    }

    let allHeld = this.reelHeldCounter.every(rh=>rh==1);
    if(allHeld)
    {
      this.reelsHaveSpun();
    }
    else
    {
      this.spinReelsFlag = true;
    }
  }

  stoppaReelEvent(reelIndex)
  {
    if(reelIndex != this.stoppaReelIndex)return;
    this.stoppaReelPause = true;
    let diff = this.reels[reelIndex].rotation.x - this.cachedRotations[reelIndex];
    let numberOfFruitsPassed = Math.round(diff/rwxGeometry.toRadians(this.oneRotation));
    let counterIndex = this.currentFruits[reelIndex];
    for(let f=0;f<numberOfFruitsPassed;f++)
    {
      counterIndex = counterIndex==this.numberOfFruits-1 ? 0 : counterIndex+1;
    }

    this.reels[reelIndex].rotation.x = counterIndex * rwxGeometry.toRadians(this.oneRotation);
    this.nextSpinValues[reelIndex] = counterIndex * rwxGeometry.toRadians(this.oneRotation);
    this.currentFruits[reelIndex] = counterIndex;
    this.flashButtonsReset();
    if(this.stoppaReelIndex == this.numberOfReels-1)
    {
      this.stoppaReelFlag = false;
      this.determineWinLine();
      this.reelsHaveSpun();
      return;
    }
    else
    {
      this.stoppaReelIndex +=1 ;
      this.bonusStoppaReelSpeedDegrees = rwxGeometry.toRadians((this.bonusStoppaReelSpeed+(this.stoppaReelIndex * this.bonusStoppaReelIncrement)));
      this.stoppaReelPause = false;
    }
  }

  stopReelStrike(winner=false)
  {
    if(winner)
    {
      this.amountOfReelStrikes -= 1;
      if(this.hasReelStrikes())
      {
        this.rwxDuoSelector.setValues([{displayName: 'Gamble', value: 'gamble'}, {displayName: `Collect`, value: 'collect'}]).then((res)=>{
          if(res=="gamble")
          {
            this.reelStrikeFlag = true;
          }
          else
          {
            this.amountOfReelStrikes = 0;
            this.spinReelsEvent(winner); 
            this.reelStrikeData.cache.material.opacity = 0.5;
          }
        })
      }
      else
      {
        this.spinReelsEvent(winner);
        this.reelStrikeData.cache.material.opacity = 0.5;
      }
    }
    else
    {
      this.reelStrikeStopFlag = true;
    }
  }





  //
  // *** ANIMATIONS
  // *** These are all called from the animation loop when a certain variable is set
  //

  reelStrike()
  {
    if(!this.reelStrikeStep1)
    {
      let speed = this.reelStrikeSpeed;
      if(this.reelStrikeStopFlag)
      {
        speed = rwxAnimate.fromTo(this.reelStrikeSpeed, (this.reelStrikeSpeed*4), 'fromToReelStrike', 'linear', 3000, () => { this.reelStrikeStep1 = true; });
      }
      if(this.reelStrikeData.timeoutCounter >= speed)
      {
        if(this.reelStrikeData.horizontalCounter == this.reels.length-1 && this.reelStrikeData.direction=="fwd"){this.reelStrikeData.direction = "dwn";}
        if(this.reelStrikeData.verticalCounter == 2 && this.reelStrikeData.direction=="dwn"){this.reelStrikeData.direction = "bck";}
        if(this.reelStrikeData.horizontalCounter == 0 && this.reelStrikeData.direction=="bck"){this.reelStrikeData.direction = "up";}
        if(this.reelStrikeData.verticalCounter == 0 && this.reelStrikeData.direction=="up"){this.reelStrikeData.direction = "fwd";}
        if(this.reelStrikeData.direction=="fwd") this.reelStrikeData.horizontalCounter += 1;
        if(this.reelStrikeData.direction=="dwn") this.reelStrikeData.verticalCounter += 1;
        if(this.reelStrikeData.direction=="bck") this.reelStrikeData.horizontalCounter -= 1;
        if(this.reelStrikeData.direction=="up") this.reelStrikeData.verticalCounter -= 1;
        if(this.reelStrikeData.cache)this.reelStrikeData.cache.material.opacity = 0.5;
        this.reelStrikeData.verticalToUse = this.currentFruits[this.reelStrikeData.horizontalCounter]+this.reelStrikeData.verticalCounter-1;
        if(this.reelStrikeData.verticalToUse > 12){this.reelStrikeData.verticalToUse = 0;}
        else if(this.reelStrikeData.verticalToUse < 0){this.reelStrikeData.verticalToUse = 12;}
        this.reelStrikeData.cache = this.reels[this.reelStrikeData.horizontalCounter].children[this.reelStrikeData.verticalToUse].children[0]
        this.reelStrikeData.cache.material.opacity = 1;
        this.reelStrikeData.timeoutCounter = 0;
      }
      this.reelStrikeData.timeoutCounter += 1;
    }
    else
    {
      let winner = this.reelFruits[this.reelStrikeData.horizontalCounter][this.reelStrikeData.verticalToUse].name;
      Object.assign(this.reelStrikeData, this.reelStrikeDataInitial);
      this.stopReelStrike(winner);
      this.reelStrikeStep1 = false;
      this.reelStrikeStep1Ease = false;
      this.reelStrikeStopFlag = false;
      this.reelStrikeFlag = false;
    }
  }

  nudgeReel()
  {
    if(!this.nudgeReelStep1)
    {
      this.reels[this.reelToNudge].rotation.x = rwxAnimate.fromTo(this.currentSpinValues[this.reelToNudge], this.nextSpinValues[this.reelToNudge], 'fromToNudgeReel', 'easeOutQuad', this.nudgeDuration, () => { this.nudgeReelStep1 = true; });
    }
    else
    {
      this.nudgeReelFlag = false;
      this.nudgeReelStep1 = false;
      this.nudgeReelStep1Ease = false;
      this.nudgeReelFlag = false;
    }
  }

  stoppaReel()
  {
    if(this.stoppaReelPause)return;
    this.reels[this.stoppaReelIndex].rotation.x += this.bonusStoppaReelSpeedDegrees;
    this.flashButtons(false, this.stoppaReelIndex);
  }

  spinNumberReel()
  {
    if(!this.spinNumberReelStep1)
    {
      let val = rwxAnimate.getEasingValue('easingValueSpinNumberReel', 'easeOutCubic', 500, () => { this.spinNumberReelStep1 = true; });
      if(this.numberReelPreviousRotation>this.numberReelCurrentRotation)
      {
        if(this.numberReelCurrentRotation == 0)
        {
          this.numberReel.rotation.y = rwxAnimate.fromToCalc(this.numberReelPreviousRotation, 0, val);
        }
        else
        {
          this.numberReel.rotation.y = rwxAnimate.fromToCalc(this.numberReelPreviousRotation, this.numberReelPreviousRotation, val);
        }
      }
      else
      {
        this.numberReel.rotation.y = rwxAnimate.fromToCalc(this.numberReelPreviousRotation, this.numberReelCurrentRotation, val);
      }
    }
    else
    {
      this.spinNumberReelStep1 = false
      this.spinNumberReelStep1Ease = false;
      this.numberReelSpinFlag = false;
    }
  }

  spinReels()
  { 
    for(let [i, reel] of this.reels.entries())
    {
      if(this.spinTimeoutCounter <= (this.spinTimeout*i)){continue;}
      else
      {
        if(!this[`spinReelsStep1-${i}`])
        {
          if(!this.reelHeldCounter[i] == 1)
          {
            reel.rotation.x = rwxAnimate.fromTo(this.currentSpinValues[i], (this.currentSpinValues[i] - this.spinMoveBackDegrees), `fromToSpinReelsStep1-${i}`, 'easeOutQuad', this.spinMoveBackDuration, () => { this[`spinReelsStep1-${i}`] = true; });
          }
        }
        else
        {
          if(!this[`spinReelsStep2-${i}`])
          {
            if(!this.reelHeldCounter[i] == 1)
            {
              reel.rotation.x = rwxAnimate.fromTo((this.currentSpinValues[i]-this.spinMoveBackDegrees), (this.spinLength + this.nextSpinValues[i]), `fromToSpinReelsStep2-${i}`, 'easeInOutQuint', this.spinDuration, () => { this[`spinReelsStep2-${i}`] = true; });
            }    
          }
          else
          {
            if(i == (this.reels.length-1)){
              this.reelsHaveSpun();
              for(let i=0;i<this.reels.length;i++)
              {
                this[`spinReelsStep1-${i}`] = false;
                this[`spinReelsStep1-${i}Ease`] = false;
                this[`spinReelsStep2-${i}`] = false;
                this[`spinReelsStep2-${i}Ease`] = false;
              }
              this.spinTimeoutCounter = 0;
              this.spinReelsFlag = false;
            }            
          }
        }        
      }
    }
    this.spinTimeoutCounter += 1;
  }

  judderReels()
  {
    for(let [i, reel] of this.reels.entries())
    {
      if(this.judderReelCounter[i] == 0) {reel.rotation.x -= rwxGeometry.toRadians(1);}
      if(this.judderReelCounter[i] == 4) {reel.rotation.x -= rwxGeometry.toRadians(2);}
      if(this.judderReelCounter[i] == 8) {reel.rotation.x += rwxGeometry.toRadians(1);}
      if(this.judderReelCounter[i] == 12) {reel.rotation.x += rwxGeometry.toRadians(2);}
      this.judderReelCounter[i] += 1;
      if(this.judderReelCounter[i] == 16) this.judderReelCounter[i] = 0;     
    }     
  }

  winAnimation()
  {
    this.flashButtons();
    if(this.winAnimationTimeoutCounter < this.winJudderDuration)
    {
      this.judderReels();
    }
    else
    {
      if(!this.winSpin)
      {
        let val = rwxAnimate.getEasingValue('easingValueWinAnimation', 'linear', this.winAnimationDuration, () => { this.winSpin = true; });
        for(let [i, reel] of this.reels.entries())
        {
          if(i%2==0)
          {
            reel.rotation.x = rwxAnimate.fromToCalc(this.nextSpinValues[i], (this.nextSpinValues[i]-this.winSpinLength), val);
          }
          else
          {
            reel.rotation.x = rwxAnimate.fromToCalc(this.nextSpinValues[i], (this.nextSpinValues[i]+this.winSpinLength), val);
          } 
        }
        this.numberReel.rotation.y = rwxAnimate.fromToCalc(0, (this.numberReelCurrentRotation + this.winSpinLength), val);
      }

      if(!this.zoomThroughReelsStep1)
      { 
        let val = rwxAnimate.getEasingValue('easingValueWinAnimationZTRS1', 'easeOutCubic', this.zoomThroughReelsStep1Duration, () => { this.zoomThroughReelsStep1 = true; });
        this.camera.position.set(rwxAnimate.fromToCalc(this.cameraPositionDefault.x, this.zoomThroughReelsStartX, val), rwxAnimate.fromToCalc(this.cameraPositionDefault.y, this.reelYPos, val), rwxAnimate.fromToCalc(this.cameraPositionDefault.z, this.zoomThroughReelsZ, val));  
      }
      else
      {
        if(!this.zoomThroughReelsStep2)
        {
          let x = rwxAnimate.fromTo(this.zoomThroughReelsStartX, this.zoomThroughReelsFinishX, 'easingValueWinAnimationZTRS2', 'easeInOutQuad', this.zoomThroughReelsStep2Duration, () => { this.zoomThroughReelsStep2 = true; });
          this.camera.position.set(x, this.reelYPos, this.zoomThroughReelsZ);      
        }
        else
        {
          if(!this.zoomThroughReelsStep3)
          {
            let val3 = rwxAnimate.getEasingValue('easingValueWinAnimationZTRS3', 'easeInOutQuad', this.zoomThroughReelsStep3Duration, () => { this.zoomThroughReelsStep3 = true; });
            this.camera.position.set(rwxAnimate.fromToCalc(this.zoomThroughReelsFinishX, this.cameraPositionDefault.x, val3), rwxAnimate.fromToCalc(this.reelYPos, this.cameraPositionDefault.y, val3), rwxAnimate.fromToCalc(this.zoomThroughReelsZ, this.cameraPositionDefault.z, val3));                
          }
          else
          {
            this.flashButtonsReset();
            this.winSpin = false;
            this.winSpinEase = false;
            for(let z=1;z<4;z++)
            {
              this[`zoomThroughReelsStep${z}`] = false;
              this[`zoomThroughReelsStep${z}Ease`] = false;
            }
            let counter = 0;
            this.judderReelCounter = [];
            for(let jr=0;jr<this.numberOfReels;jr++)
            {
              this.judderReelCounter.push(counter);
              counter += 2;
            }
            this.winAnimationTimeoutCounter = 0;
            this.hasWonFlag = false;
          }
        }
      }
    }
    this.winAnimationTimeoutCounter +=1;
  }

  flashButtonsReset()
  {
    this.flashButtonsFlag = false;
    this.flashOpacityCounter = 1;
    this.flashOpacityDirection = "DOWN";
    for(let button of this.reelControlButtons)
    {
      button.material.opacity = 0.5;
      button.position.y = this.buttonYPosition;
    }    
  }

  flashButtons(all=true, index=0)
  {
    if(this.flashOpacityCounter <= 0.5)this.flashOpacityDirection = "UP";
    if(this.flashOpacityCounter >= 1)this.flashOpacityDirection = "DOWN";

    this.flashOpacityCounter = this.flashOpacityDirection == "DOWN" ? this.flashOpacityCounter - this.flashButtonsSpeed : this.flashOpacityCounter + this.flashButtonsSpeed;

    for(let [i, button] of this.reelControlButtons.entries())
    {
      if(!all && i!=index){continue;}
      if(this.reelHeldCounter[i] === 1){
        button.material.opacity = 1;
        button.position.y = this.buttonYPosition;
      }
      else
      {
        button.material.opacity = this.flashOpacityCounter;
        button.position.y = this.buttonYPosition - (1-this.flashOpacityCounter);
      }
    }
  }

  fruitMachineInitAnimation()
  {
    for(let [i, reelFace] of this.fruitMachineInitAnimationData.reelFaces.entries())
    {
      if(this.timeoutCounter <= reelFace.timeout){continue;}
      else
      {
        if(!this[`fruitMachineInitialAnimationStep1-${i}`])
        {
          let val = rwxAnimate.getEasingValue(`easingValueFruitMachineInitialAnimationStep1-${i}`, 'easeInOutQuart', 4000, () => { this[`fruitMachineInitialAnimationStep1-${i}`] = true; });
          reelFace.face.position.set(rwxAnimate.fromToCalc(reelFace.start.x, reelFace.finish.x, val), rwxAnimate.fromToCalc(reelFace.start.y, reelFace.finish.y, val), rwxAnimate.fromToCalc(reelFace.start.z, reelFace.finish.z, val));
          reelFace.face.rotation.y = rwxAnimate.fromToCalc(0, (this.initialAnimationConfigurables.amountOfSpin + reelFace.rotation), val);
        }
        else
        {
          if(i == (this.fruitMachineInitAnimationData.reelFaces.length-1) && this.fruitMachineInitialAnimationStep3 && this.fruitMachineInitialAnimationStep2 && this.fruitMachineInitialAnimationStep4 && this.fruitMachineInitialAnimationStep5){
            this.fruitMachineInitAnimationFlag = false;
          }            
        }
      }
    }

    if(this.enableNumbers && !this.fruitMachineInitialAnimationStep5)
    {
      if(this.timeoutCounter> 100)
      {
        let val4 = rwxAnimate.getEasingValue('easingValueFruitMachineInitialAnimationStep5', 'easeOutQuart', 5000, () => { this.fruitMachineInitialAnimationStep5 = true; });
        for(let numberReel of this.fruitMachineInitAnimationData.numberReel)
        {
          numberReel.reel.position.y = rwxAnimate.fromToCalc(numberReel.start.y, 0, val4);
          numberReel.reel.position.z = rwxAnimate.fromToCalc(numberReel.start.z, numberReel.finish.z, val4);
          numberReel.reel.rotation.y = rwxAnimate.fromToCalc(0, this.initialAnimationConfigurables.amountOfSpin, val4);
        }
      }        
    }
    else
    {
      this.initialAnimationStep5 = true;
    }

    if(!this.fruitMachineInitialAnimationStep4)
    {
      let val1 = rwxAnimate.getEasingValue('easingValueFruitMachineInitialAnimationStep4', 'easeInOutCubic', 5000, () => { this.fruitMachineInitialAnimationStep4 = true; });
      for(let button of this.fruitMachineInitAnimationData.buttons)
      {
        button.button.position.y = rwxAnimate.fromToCalc(button.starty, button.finishy, val1);
      }      
    }

    if(!this.fruitMachineInitialAnimationStep3 && this.timeoutCounter > 20)
    {
      let val3 = rwxAnimate.getEasingValue('easingValueFruitMachineInitialAnimationStep3', 'easeInOutQuart', 4000, () => { this.fruitMachineInitialAnimationStep3 = true; });
      for(let reelEdge of this.fruitMachineInitAnimationData.reelEdges)
      {
        reelEdge.edge.position.y = rwxAnimate.fromToCalc(reelEdge.starty, reelEdge.finishy, val3);
        reelEdge.outline.position.y = rwxAnimate.fromToCalc(reelEdge.starty, reelEdge.finishy, val3);
      }
    }

    if(!this.fruitMachineInitialAnimationStep2)
    {
      let val2 = rwxAnimate.getEasingValue('easingValueFruitMachineInitialAnimationStep2', 'easeInOutQuad', 6000, () => { this.fruitMachineInitialAnimationStep2 = true; });
      this.camera.position.x = rwxAnimate.fromToCalc((this.cameraPositionDefault.x+this.initialAnimationConfigurables.cameraX), this.cameraPositionDefault.x, val2);
      this.camera.position.z = rwxAnimate.fromToCalc((this.cameraPositionDefault.z+this.initialAnimationConfigurables.cameraZ), this.cameraPositionDefault.z, val2);
    }
    this.timeoutCounter+=1;
  }
}