import * as THREE from 'three';
//import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { RectAreaLightUniformsLib } from 'three/examples/jsm/lights/RectAreaLightUniformsLib.js';
import { CSS2DRenderer, CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer.js';

import { Configuration } from './fruitMachineConfiguration.js';
import { FruitMachineData, FeatureBoardData } from './fruitMachineData';
import { FruitMachine } from './fruitMachine.js';

/* Roseworx */
import rwxPreload from 'roseworx/js/modules/rwxPreload';
import rwxSlideTicker from 'roseworx/js/components/rwxSlideTicker';
import rwxTombola from 'roseworx/js/components/rwxTombola';
import rwxNumberSwitchers from 'roseworx/js/components/rwxNumberSwitchers';
import rwxNoticeBox from 'roseworx/js/components/rwxNoticeBox';
import rwxDuoSelector from 'roseworx/js/components/rwxDuoSelector';

import rwxMath from 'roseworx/js/helpers/rwxMathHelpers';
import rwxGeometry from 'roseworx/js/helpers/rwxGeometryHelpers';
import rwxAnimate from 'roseworx/js/helpers/rwxAnimateHelpers';

/* Plugins */
import MeasureMeter from './measureMeter/measureMeter.js';
import NumberPicker from './numberPicker/numberPicker.js';
import tippy from 'tippy.js';
//require('tippy.js/animations/scale.css');

export class FruitMachineCore extends FruitMachine {
	constructor(element)
	{
		super();
    window.addEventListener('load', ()=>{this.init(element);});
    window.addEventListener('unload', ()=>{this.unInit()});
    window.addEventListener('resize', ()=>{this.onResize()});
	}

  unInit()
  {
    localStorage.setItem('storedCoins', this.amountOfCoins);
  }

  init(element)
  {
    this.container = document.querySelector(element);
    if(!this.container){return;}

    Object.assign(this, Configuration);
    Object.assign(this, FruitMachineData);
    Object.assign(this, FeatureBoardData);

    this.width = window.innerWidth;
    this.height = window.innerHeight;    

    this.createVariables();
    this.createDynamicVariables();
    this.addPlugins();
    this.createScene();
    this.createCamera();
    this.createLights();

    this.createFruitMachine();
    this.createFeatureBoard();

    this.helperTips();

    this.createRenderer();

    this.featureBoardDevelop && this.developingFeatureBoard();
    this.fruitMachineDevelop && this.developingFruitMachine();
		this.fruitMachineInitAnimationFlag = (!this.fruitMachineDevelop && !this.featureBoardDevelop);
    
    this.eventDispatcher();
    this.renderer.setAnimationLoop(() => {
      this.update();
    });

    this.amountOfCoins = localStorage.getItem('storedCoins') ? parseInt(localStorage.getItem('storedCoins')) : this.startingCoins;
    document.querySelector('.loading').remove();
  }

  onResize()
  {
    this.width = window.innerWidth;
    this.height = window.innerHeight;
    this.camera.aspect = this.width / this.height;
    this.camera.updateProjectionMatrix();
    this.renderer.setSize( this.width, this.height );     
  }

  helperTips()
  {
    if(!this.enableHelperTips){return;}
    new tippy('[helper-tip]', {
      //animation: 'scale',
      content(ref) {
        const id = ref.getAttribute('data-helper-tip-content-id');
        const template = document.getElementById(id);
        return template;
      },
      interactive:true,
      placement:'auto',
      trigger: 'click'
    });
    const helperTips = [...document.querySelectorAll('.helper-tip')].map(ht=>ht.id);
    document.getElementById('helper-tip-toggler').addEventListener('click', (ev)=>{
      ev.target.classList.toggle('active');
      this.showDOMelements(ev.target.classList.contains('active'), helperTips);
    });
  }

  showDOMelements(show, identifiers)
  {
    for(let dm of identifiers)
    {
      let el = document.getElementById(dm)
      show && el.classList.remove('dom-element-hide');
      !show && el.classList.add('dom-element-hide');
    }
  }

  checkAndApplyHelperTip(mesh, id)
  {
    if(!this.enableHelperTips){return;}
    let helperTip = document.getElementById(id);
    if(helperTip)
    {
      helperTip = new CSS2DObject(helperTip);
      mesh.add(helperTip);
    }
  }

  assignVariables(array)
  {
  	array.falses && array.falses.map((f)=>{this[f] = false;});
  	array.zeros && array.zeros.map((z)=>{this[z] = 0;});
  	array.arrays && array.arrays.map((a)=>{this[a] = [];});
  	array.threeGroups && array.threeGroups.map((tg)=>{this[tg] = new THREE.Group();});
  }

  createVariables()
  {
  	let initVariables = {
  		falses: ['hasCollected', 'reelStrikeStopFlag', 'reelStrikeFlag', 'featureBoardInitAnimationFlag', 'fruitMachineInitAnimationFlag', 'numberReelSpinFlag', 'hasFeatureFlag', 'stoppaReelFlag', 'hasWonFlag', 'spinReelsFlag', 'nudgeReelFlag', 'flashButtonsFlag', 'rollDiceFlag', 'rotateFeatureBoardCubeFlag', 'moveInFlag', 'moveOutFlag', 'hasHolds2', 'hasBonus', 'numberTrailHeld' ],
  		zeros: ['spinTimeoutCounter', 'timeoutPerCubeCounter', 'numberOfIcons', 'numberOfHearts', 'amountOfReelStrikes1', 'amountOfNudges1', 'timeoutCounter', 'winValue', 'reelStrikeCount1', 'nudgeCount1', 'coinCount1', 'streakStrengthCount1', 'currentNumberIndex'],
  		arrays: ['iconsArray', 'heartsArray', 'currentFruits', 'currentSpinValues', 'nextSpinValues', 'reels', 'reelFruits', 'reelControlButtons', 'numberReelFaces', 'outerCubes', 'innerCubes', 'dice'],
  		threeGroups: ['fbGeometryGroup', 'fbPersistentZGroup', 'fbCubesGroup']
  	};

  	this.assignVariables(initVariables);

    this.flashOpacityCounter = 1;
    this.fruitMachineInitAnimationData = {reelFaces:[], reelEdges: [], buttons: [], numberReel:[]};
    this.nudgeReelCounter = new Array(this.numberOfReels).fill(0);
    this.reelHeldCounter = new Array(this.numberOfReels).fill(0);
    if(!this.enableNumbers)
    {
      this.bonuses = this.bonuses.filter(b=>b.functionToRun!="bonusNumbersInView");
    }
    this.reelYPos = 8.5;
    this.numberOfNumbers = this.numbers.length;
    this.numberOfFruits = this.fruits.length > 5 ? this.fruits.length : 5;
    if(this.enableBonus){this.numberOfFruits+=1;}
    this.oneRotation = (360 / this.numberOfFruits);
    this.spinLength = rwxGeometry.toRadians(360 * this.numberOfFullRotations);
    this.spinMoveBackDegrees = rwxGeometry.toRadians(this.degreesToMoveBackBeforeSpinning);
    this.spinDuration = this.spinReelsDuration * 0.92;
    this.spinMoveBackDuration = this.spinReelsDuration * 0.08;
    this.zoomThroughReelsZ = this.reelSize * (this.zoomThroughReelsZPercentage/100);
    this.zoomThroughReelsFinishX = (this.numberOfReels * this.reelWidth) + (this.numberOfReels-1 * this.reelSpacing) + this.zoomThroughReelsXGap;
    this.zoomThroughReelsStartX = -this.zoomThroughReelsFinishX;
    this.zoomThroughReelsStep1Duration = this.winAnimationDuration * 0.25;
    this.zoomThroughReelsStep2Duration = this.winAnimationDuration * 0.50;
    this.zoomThroughReelsStep3Duration = this.winAnimationDuration * 0.25; 
    this.winSpinLength = rwxGeometry.toRadians(360*2);
    this.reelStrikeDataInitial = {verticalCounter: 0, horizontalCounter: 0, timeoutCounter: 0, direction: "fwd"};
    this.reelStrikeData = {};
    Object.assign(this.reelStrikeData, this.reelStrikeDataInitial);

    // feature board
    this.rollEvenOddDegrees = rwxGeometry.toRadians(this.rollEvenOddSpeed);
		this.fbCameraDistance = 40;
    this.outerBoardCubeSpacing = this.cubeSize+2.5;
    this.innerBoardCubeSpacing = this.cubeSize+4;
    this.innerBoardDistance = 50;
    this.innerBoardInactiveOpacity = 0.1;
    this.currentBoard = "outer";
    this.cubeLightPosition = new THREE.Vector3();
    this.diceSpinLength = rwxGeometry.toRadians(360 * this.diceSpinFullRotations);
    this.fbDOMElements = [
      {
        domID: 'feature-board-nudge-count-container',
        position: new THREE.Vector2(-8, 10),
        helperTipID: 'helper-tip-fb-nudge-counter'
      },
      {
        domID: 'feature-board-reel-strike-count-container',
        position: new THREE.Vector2(0, 10),
        helperTipID: 'helper-tip-fb-reel-strike-counter'
      },
      {
        domID: 'feature-board-coin-count-container',
        position: new THREE.Vector2(8,10),
        helperTipID: 'helper-tip-fb-coin-counter'
      },
      {
        domID: 'feature-board-streak-meter-container',
        position: new THREE.Vector2(0,6),
        helperTipID: 'helper-tip-fb-streak-meter'
      },
      {
        domID: 'feature-board-heart1-container',
        position: new THREE.Vector2(-9,-9.5),
        helperTipID: 'helper-tip-fb-heart-counter'
      },
      {
        domID: 'feature-board-heart2-container',
        position: new THREE.Vector2(-6, -6)
      },
      {
        domID: 'feature-board-heart3-container',
        position: new THREE.Vector2(-3, -9.5)
      },
      {
        domID: 'feature-board-icon1-container',
        position: new THREE.Vector2(3,-9.5),
        helperTipID: 'helper-tip-fb-icon-counter'
      },
      {
        domID: 'feature-board-icon2-container',
        position: new THREE.Vector2(6, -6)
      },
      {
        domID: 'feature-board-icon3-container',
        position: new THREE.Vector2(9, -9.5)
      }
    ]
    this.collectables = [
      {domID:'feature-board-streak-meter-container', variable: 'streakStrengthCount', text:'Streak', fmMethodToCall: 'streak'}, 
      {domID:'feature-board-nudge-count-container', variable: 'nudgeCount', text:'Nudges', fmLinkVariable: 'amountOfNudges'}, 
      {domID:'feature-board-reel-strike-count-container', variable: 'reelStrikeCount', text:'Reel Strikes', fmLinkVariable: 'amountOfReelStrikes', fmMethodToCall:'reelStrikes'}, 
      {domID:'feature-board-coin-count-container', variable: 'coinCount', text:'Coins', fmLinkVariable: 'amountOfCoins'}
    ];

    this.availableForMultiAdd = ['add-heart', 'add-icon', 'add-money', 'add-reel-strikes', 'add-streak-strength', 'add-nudges']; // hooks up to a name property in the cubeTextures array to specify which ones can be won on multi add
    this.maxNumberOfIcons = 3; // maximum number of hearts or icons before extra life/ game
  
    //general
    this.initialAnimationConfigurables = { // for both fruit machine and feature board initial animations
      reelEdgeOffset: 500,
      reelFaceOffset: 800,
      amountOfSpin: rwxGeometry.toRadians(720),
      cameraX: 100,
      cameraZ: 40,
      timeoutPerCube: 10
    };

    this.cameraPositionDefault = new THREE.Vector3(-10, 27, 55);
    this.featureCameraPositionDefault = new THREE.Vector3(this.zoomThroughReelsFinishX, this.reelYPos, 0);
    this.raycaster = new THREE.Raycaster();
    this.mouse = new THREE.Vector2();
  }

  createDynamicVariables()
  {
    Object.defineProperty(this, 'amountOfCoins', { // for reels
      get: function () { return this.amountOfCoins1; },
      set: function (v) {
        this.amountOfCoins1 = v;
        rwxNumberSwitchers.switch('coins', v);
      }
    });

    Object.defineProperty(this, 'amountOfReelStrikes', { // for reels
      get: function () { return this.amountOfReelStrikes1 },
      set: function (v) {
        this.amountOfReelStrikes1 = v;
        if(v==0)
        {
          this.rwxNoticeBox.close();
        }
        else
        {
          let word = v == 1 ? 'Strike' : 'Strikes';
          this.rwxNoticeBox.setValue(`${v} Reel ${word}`, v==0);
        }
      }
    });

    Object.defineProperty(this, 'amountOfNudges', { // for reels
      get: function () { return this.amountOfNudges1; },
      set: function (v) {
        this.amountOfNudges1 = v;
        if(v==0)
        {
          this.rwxNoticeBox.close();
          this.flashButtonsReset();
        }
        else
        {
          this.flashButtonsFlag = true;
          let word = v == 1 ? 'Nudge' : 'Nudges';
          if(this.reverseNudge){word = 'Reverse '+word}
          this.rwxNoticeBox.setValue(`${v} ${word}`, v==0);
        }
      }
    });

    Object.defineProperty(this, 'hasHolds', { // for reels
      get: function () { return this.hasHolds2; },
      set: function (v) {
        if(!v)
        {
          this.rwxNoticeBox.close();
          this.flashButtonsReset();
        }
        else
        {
          this.flashButtonsFlag = true;
          const innerText = this.hasHolds2 && !v ? '' : 'hold';
          this.hasHolds2 = v;
          this.rwxNoticeBox.setValue(innerText);
        }
      }
    });

    Object.defineProperty(this, 'streakStrengthCount', { // for featureboard
      get: function () { return this.streakStrengthCount1; },
      set: function (v) {
        this.streakStrengthCount1 = v;
        this.streakMeter.to(v);
      }
    });

    Object.defineProperty(this, 'reelStrikeCount', { //for featureboard
      get: function () { return this.reelStrikeCount1; },
      set: function (v) {
        this.reelStrikeCount1 = v;
        rwxNumberSwitchers.switch('feature-board-reel-strike-count', v, true);
      }
    });

    Object.defineProperty(this, 'nudgeCount', { // for featureboard
      get: function () { return this.nudgeCount1; },
      set: function (v) {
        this.nudgeCount1 = v;
        rwxNumberSwitchers.switch('feature-board-nudge-count', v, true);
      }
    });

    Object.defineProperty(this, 'coinCount', { // for featureboard
      get: function () { return this.coinCount1; },
      set: function (v) {
        this.coinCount1 = v<0 ? 0 : v;
        rwxNumberSwitchers.switch('feature-board-coin-count', v);
      }
    });
  }

  addPlugins()
  {
    this.rwxNoticeBox = rwxNoticeBox;
    this.rwxNoticeBox.setConfig({ position: "bottom-left", delay: 2 });
    this.rwxDuoSelector = rwxDuoSelector;
    this.rwxSlideTicker = rwxSlideTicker;
    this.rwxTombola = rwxTombola;
    this.streakMeter = new MeasureMeter('#feature-board-streak-meter', 10);
    this.numberPicker = new NumberPicker('#number-picker');
  }

  createScene()
  {
    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color( this.backgroundColor );    
  }

  createCamera()
  {
    this.fieldOfView = 50;
    const aspectRatio = this.width / this.height;
    const nearViewingPlane = 1;
    const farViewingPlane = 800;
    this.camera = new THREE.PerspectiveCamera( this.fieldOfView, aspectRatio, nearViewingPlane, farViewingPlane );
    this.camera.position.set( this.cameraPositionDefault.x , this.cameraPositionDefault.y, this.cameraPositionDefault.z );
  }

  createLights()
  {
    RectAreaLightUniformsLib.init();
    this.reelIllumination = new THREE.RectAreaLight( this.reelIlluminationColor, 4, this.fieldOfView*2, this.reelSize );
    this.reelIllumination.position.set( 0, 10, 60 );
    this.scene.add( this.reelIllumination );

    this.sceneLight = new THREE.HemisphereLight( this.reelsColor, 0xffffff, 3 );
    this.sceneLight.position.set(0, 50, 0);
    this.scene.add( this.sceneLight );

    this.featureBoardLight = new THREE.DirectionalLight( 0xffffff, 0 );
    this.featureBoardLight.position.set(this.featureCameraPositionDefault.x, this.featureCameraPositionDefault.y, this.featureCameraPositionDefault.z);
    this.scene.add( this.featureBoardLight );

    this.cubeLight = new THREE.PointLight( 0xffffff, 0, 10 );
    this.scene.add(this.cubeLight);

    // var pointLightHelper = new THREE.PointLightHelper( this.cubeLight, 1 );
    // this.scene.add(pointLightHelper);
  }

  createRenderer()
  {
    this.renderer = new THREE.WebGLRenderer({ antialias: true, sortObjects: false });
    this.renderer.setSize( this.width, this.height );
    this.renderer.setPixelRatio( window.devicePixelRatio );
    this.renderer.physicallyCorrectLights = true;
    this.container.appendChild( this.renderer.domElement );

    this.labelRenderer = new CSS2DRenderer();
    this.labelRenderer.setSize( this.width, this.height );
    this.labelRenderer.domElement.style.position = 'absolute';
    this.labelRenderer.domElement.style.top = 0;
    this.container.appendChild( this.labelRenderer.domElement );
    //this.controls = new OrbitControls( this.camera, this.renderer.domElement );
  }

  eventDispatcher()
  {
    document.body.addEventListener('keyup', (ev)=>{
      if(this.featureActive)
      {
        ev.keyCode == 13 && this.collect();
        (ev.keyCode == 32 && !this.hilo) && this.rollDiceEvent("ROLL");
        (ev.keyCode == 38 && this.ableToGambleHI) && this.rollDiceEvent("GAMBLEHI");
        (ev.keyCode == 40 && this.ableToGambleLO) && this.rollDiceEvent("GAMBLELO");
      }
      else
      {
        (ev.keyCode == 32 && !this.streakRunning) && this.spinReelsEvent();
      }
    });

    this.container.addEventListener('mouseup', (ev)=>{
      if(this.rollEvenOddFlag){this.rollEvenOddStop(); return;}
      if(this.stoppaOrTaxiRunning){this.stopStoppaOrTaxi(); return;}
      if(this.reelStrikeFlag){this.stopReelStrike(); return;}
      if(!this.hasNudges() && !this.hasHolds && !this.stoppaReelFlag){return;}
      this.mouse.x = ( ev.clientX / this.width ) * 2 - 1;
      this.mouse.y = -( ev.clientY / this.height ) * 2 + 1;
      this.raycaster.setFromCamera( this.mouse, this.camera );
      let intersects = this.raycaster.intersectObjects( this.reelControlButtons );
      if(intersects.length > 0)
      {
        this.hasNudges() && this.nudgeReelEvent(intersects[0].object.reelIndex);
        this.hasHolds && this.holdReelEvent(intersects[0].object.reelIndex);
        this.stoppaReelFlag && this.stoppaReelEvent(intersects[0].object.reelIndex);
      }
    });
  }

  setTombola(arr, variable) // variable is something on the instance to set to false after callback
  {
    this.rwxTombola.setValues(arr).then((winner)=>{
      this[variable] = false;
      try { this[winner](); }
      catch(err)
      {
        console.log(err);
        console.log(`${winner} method not defined on the fruitMachine context.`)
      }
    });
  }

  render()
  {
    this.renderer.render( this.scene, this.camera );
    this.labelRenderer.render( this.scene, this.camera );
  }

  update()
  {
    this.fruitMachineInitAnimationFlag && this.fruitMachineInitAnimation();
    this.featureBoardInitAnimationFlag && this.featureBoardInitAnimation();

    this.spinReelsFlag && this.spinReels();
    this.nudgeReelFlag && this.nudgeReel();
    this.stoppaReelFlag && this.stoppaReel();
    this.reelStrikeFlag && this.reelStrike();
    this.flashButtonsFlag && this.flashButtons();
    this.numberReelSpinFlag && this.spinNumberReel();
    (this.hasWonFlag && !this.spinReelsFlag && !this.nudgeReelFlag) && this.winAnimation();
    (this.hasFeatureFlag && !this.nudgeReelFlag && !this.spinReelsFlag && !this.numberReelSpinFlag) && this.toFeatureAnimation();

    this.rollDiceFlag && this.rollDice();
    this.endFeatureFlag && this.toFruitMachineAnimation();
    this.rotateFeatureBoardCubeFlag && this.rotateFeatureBoardCube();
    this.moveInFlag && this.moveIn();
    this.moveOutFlag && this.moveOut();
    this.rollEvenOddFlag && this.rollEvenOdd();

    if(!this.featureActive && !this.hasFeatureFlag)
    {
      this.camera.lookAt(0,0,0);
    }
    if(this.featureBoardDevelop)
    {
      this.camera.lookAt(this.featureCameraPositionDefault.x+this.fbCameraDistance+this.innerBoardDistance, this.featureCameraPositionDefault.y, this.featureCameraPositionDefault.z);
    }
    this.render();
  }

  //
  // *** For development purposes (e.g skipping initial animations, setting camera correct, etc,  anything we dont wanna view when developing)
  //
  //

  developingFeatureBoard()
  {
    this.showDOMelements(true, this.fbDOMElements.map(de=>de.domID));
    this.camera.position.set(this.featureCameraPositionDefault.x, this.featureCameraPositionDefault.y, this.featureCameraPositionDefault.z);
    this.showDOMelements(false, ['coins']);
    this.featureActive = true;
    this.switchLights("feature-board");
    this.featureBoardLight.target = this.dice[0];
    for(let c of this.outerCubes)
    {
      c.cube.rotation.x = rwxGeometry.toRadians(0);
      c.cube.material.map(c=> c.opacity=1);
    }
  }

  developingFruitMachine()
  {
    for(let [i, reelFace] of this.fruitMachineInitAnimationData.reelFaces.entries())
    {
      reelFace.face.position.set(reelFace.finish.x,reelFace.finish.y,reelFace.finish.z);
      reelFace.face.rotation.y = reelFace.rotation;       
    }
    if(this.enableNumbers)
    {
      for(let numberReel of this.fruitMachineInitAnimationData.numberReel)
      {
        numberReel.reel.position.y = 0;
        numberReel.reel.position.z = numberReel.finish.z;
      }
    }
    for(let button of this.fruitMachineInitAnimationData.buttons)
    {
      button.button.position.y = button.finishy;
    }
    for(let reelEdge of this.fruitMachineInitAnimationData.reelEdges)
    {
      reelEdge.edge.position.y = reelEdge.finishy;
      reelEdge.outline.position.y = reelEdge.finishy;
    }
  }





  //
  // *** Methods which handle the conversion between featureBoard App and fruit machine App
  //
  //

  switchLights(app)
  {
    let condition = app == "feature-board";
    if(app == "feature-board")
    {
      this.reelIllumination.intensity = 0;
      this.featureBoardLight.intensity = 1;
      this.cubeLight.intensity = 7;
    }
    else if (app == "fruit-machine")
    {
      this.cubeLight.intensity = 0;
      this.featureBoardLight.intensity = 0;
      this.reelIllumination.intensity = 4;
    }
  }

  afterFeatureBoard()
  {
    this.showDOMelements(true, ['coins']);
    this.featureActive = false;
    if(this.hasCollected)
    {
      if (this.hasCollected.fmLinkVariable)
      {
        this[this.hasCollected.fmLinkVariable] += this[this.hasCollected.variable];
      }
      if(this.hasCollected.fmMethodToCall)
      {
        this[this.hasCollected.fmMethodToCall](this[this.hasCollected.variable]);
      }
      this.hasCollected = false;
    }
    for(let cube of this.outerCubes)
    {
      cube.cube.rotation.x = rwxGeometry.toRadians(-90);
      cube.cube.material.map(c=> c.opacity=0);
    }
    this.assignVariables({zeros:['timeoutPerCubeCounter', 'numberOfHearts','numberOfIcons','streakStrengthCount','reelStrikeCount','nudgeCount','coinCount']});
    this.heartsArray.map(h=>h.clear());
    this.iconsArray.map(i=>i.clear());
    this.fbPersistentZGroup.position.z = 0;
    this.innerCubes.map((ic)=>{ic.cube.material.map(m=>m.opacity = this.innerBoardInactiveOpacity)});
    this.currentBoard = "outer";
  }

  toFeatureBoard()
  {
    this.rwxSlideTicker.setValue("Start Feature");
    this.hasFeatureFlag = true;
    this.resetNumberTrail();
    this.showDOMelements(false, ['coins']);
    this.featureActive = true;
    this.switchLights("feature-board");
    this.featureBoardLight.target = this.dice[0];
  }

  toFeatureAnimation()
  {
    if(!this.winSpin)
    {
      let val = rwxAnimate.getEasingValue('easingValueToFeatureWinSpin', 'easeInCubic', this.winAnimationDuration, () => { this.winSpin = true; });
      for(let [i, reel] of this.reels.entries())
      {
        if(i%2==0)
        {

          reel.rotation.x = rwxAnimate.fromToCalc(this.nextSpinValues[i], (this.nextSpinValues[i]-this.winSpinLength), val);
        }
        else
        {
          reel.rotation.x = rwxAnimate.fromToCalc(this.nextSpinValues[i], (this.nextSpinValues[i]+this.winSpinLength), val);
        } 
      }
    }
    if(!this.zoomThroughReelsStep1)
    { 
      let val = rwxAnimate.getEasingValue('easingValueToFeatureZTRS1', 'easeOutCubic', this.zoomThroughReelsStep1Duration, () => { this.zoomThroughReelsStep1 = true; });
      this.camera.position.set(rwxAnimate.fromToCalc(this.cameraPositionDefault.x, this.zoomThroughReelsStartX, val), rwxAnimate.fromToCalc(this.cameraPositionDefault.y, this.reelYPos, val), rwxAnimate.fromToCalc(this.cameraPositionDefault.z, 0, val));
      this.camera.lookAt(rwxAnimate.fromToCalc(0, (this.featureCameraPositionDefault.x+this.fbCameraDistance+this.innerBoardDistance), val), rwxAnimate.fromToCalc(0, this.featureCameraPositionDefault.y, val), rwxAnimate.fromToCalc(0, this.featureCameraPositionDefault.z,val));
    }
    else
    {
      if(!this.zoomThroughReelsStep2)
      {
        let x = rwxAnimate.fromTo(this.zoomThroughReelsStartX, this.zoomThroughReelsFinishX, 'fromToToFeatureWinZTRS2', 'easeInOutQuad', this.zoomThroughReelsStep2Duration, () => { this.zoomThroughReelsStep2 = true; });
        this.camera.position.set(x, this.reelYPos, 0);      
      }
    }
    if(this.winSpin && this.zoomThroughReelsStep1 && this.zoomThroughReelsStep2)
    { 
      this.winSpin = false;
      this.winSpinEase = false;
      this.zoomThroughReelsStep1 = false;
      this.zoomThroughReelsStep1Ease = false;
      this.zoomThroughReelsStep2 = false;
      this.zoomThroughReelsStep2Ease = false;
      this.featureBoardInitAnimationFlag = true;
      this.hasFeatureFlag = false;
    }
  }

  toFruitMachineAnimation()
  {
    if(!this.zoomToDefaultCameraStep1)
    {
      let val = rwxAnimate.getEasingValue('easingValueToFruitMachine', 'easeInQuart', 5000, () => { this.zoomToDefaultCameraStep1 = true; });

      this.cameraPositionDefault = new THREE.Vector3(-10, 27, 55);
      let addToX = this.currentBoard == "inner" ? this.innerBoardDistance : 0;
      this.camera.position.set(rwxAnimate.fromToCalc((this.featureCameraPositionDefault.x+addToX), this.cameraPositionDefault.x, val), rwxAnimate.fromToCalc(this.featureCameraPositionDefault.y, this.cameraPositionDefault.y, val), rwxAnimate.fromToCalc(this.featureCameraPositionDefault.z, this.cameraPositionDefault.z, val));
      this.camera.lookAt(rwxAnimate.fromToCalc((this.featureCameraPositionDefault.x+this.fbCameraDistance+this.innerBoardDistance), 0, val), rwxAnimate.fromToCalc(this.featureCameraPositionDefault.y, 0, val), rwxAnimate.fromToCalc(this.featureCameraPositionDefault.z, 0, val));    
    }
    else
    {
      this.afterFeatureBoard();
      this.zoomToDefaultCameraStep1 = false;
      this.zoomToDefaultCameraStep1Ease = false;
      this.endFeatureFlag = false;
    }
  }
}