import * as THREE from 'three';
const FruitMachineData = {
  numbers: [
    {
      value: 1,
      texture: new THREE.TextureLoader().load( require("./fruitMachineAssets/one.png") )
    },
    {
      value: 2,
      texture: new THREE.TextureLoader().load( require("./fruitMachineAssets/two.png") )
    },
    {
      value: 3,
      texture: new THREE.TextureLoader().load( require("./fruitMachineAssets/three.png") )
    },
    {
      value: 4,
      texture: new THREE.TextureLoader().load( require("./fruitMachineAssets/four.png") )
    },
    {
      value: 5,
      texture: new THREE.TextureLoader().load( require("./fruitMachineAssets/five.png") )       
    },
    {
      value: 6,
      texture: new THREE.TextureLoader().load( require("./fruitMachineAssets/six.png") )
    },
    {
      value: 7,
      texture: new THREE.TextureLoader().load( require("./fruitMachineAssets/seven.png") )
    },
    {
      value: 8,
      texture: new THREE.TextureLoader().load( require("./fruitMachineAssets/eight.png") )
    },
    {
      value: 9,
      texture: new THREE.TextureLoader().load( require("./fruitMachineAssets/nine.png") )
    },
    {
      value: 10,
      texture: new THREE.TextureLoader().load( require("./fruitMachineAssets/ten.png") )
    },
    {
      value: 11,
      texture: new THREE.TextureLoader().load( require("./fruitMachineAssets/eleven.png") )
    },
    {
      value: 12,
      texture: new THREE.TextureLoader().load( require("./fruitMachineAssets/twelve.png") )
    }
  ],
  fruits: [
    {
      name: "cherry",
      texture: new THREE.TextureLoader().load( require("./fruitMachineAssets/cherry.png") ),
      coinValue: 50
    },
    {
      name: "strawberry",
      texture: new THREE.TextureLoader().load( require("./fruitMachineAssets/strawberry.png") ),
      coinValue: 100
    },
    {
      name: "apple",
      texture: new THREE.TextureLoader().load( require("./fruitMachineAssets/apple.png") ),
      coinValue: 150
    },
    {
      name: "orange",
      texture: new THREE.TextureLoader().load( require("./fruitMachineAssets/orange.png") ),
      coinValue: 200
    },
    {
      name: "coconut",
      texture: new THREE.TextureLoader().load( require("./fruitMachineAssets/coconut.png") ),
      coinValue: 300
    },
    {
      name: 'watermelon',
      texture: new THREE.TextureLoader().load( require("./fruitMachineAssets/watermelon.png") ),
      coinValue: 500
    },
    {
      name: "rose",
      texture: new THREE.TextureLoader().load( require("./fruitMachineAssets/rose.png") ),
      coinValue: 1000
    },
    {
      name: "bell",
      texture: new THREE.TextureLoader().load( require("./fruitMachineAssets/bell.png") ),
      coinValue: 2000
    },
    {
      name: "star",
      texture: new THREE.TextureLoader().load( require("./fruitMachineAssets/star.png") ),
      coinValue: 3500
    },
    {
      name: "diamond",
      texture: new THREE.TextureLoader().load( require("./fruitMachineAssets/diamond.png") ),
      coinValue: 5000
    },
    {
      name: "gold-coin",
      texture: new THREE.TextureLoader().load( require("./fruitMachineAssets/gold-coin.png") ),
      coinValue: 10000
    },
    {
      name: "wild",
      texture: new THREE.TextureLoader().load( require("./fruitMachineAssets/wild.png") )
    }
  ],
  fruitMachineBonuses: [
    {
      displayName: "4 NUDGES",
      value: "bonusFourNudges"
    },
    {
      displayName: "WIN SPIN",
      value: "bonusWinSpin"
    },
    {
      displayName: "UNLUCKY",
      value: "bonusUnlucky"
    },
    {
      displayName: "2 NUDGES",
      value: "bonusTwoNudges"
    },
    {
      displayName: "STOPPA REEL",
      value: "bonusStoppaReel"
    },
    {
      displayName: "2 REEL STRIKES",
      value: "bonusTwoReelStrikes"
    },
    {
      displayName: "3 REVERSE NUDGES",
      value: "bonusThreeReverseNudges"
    },
    {
      displayName: "START FEATURE",
      value: "toFeatureBoard"
    },
    {
      displayName: "UNLUCKY",
      value: "bonusUnlucky"
    },
    {
      displayName: "4 REVERSE NUDGES",
      value: "bonusFourReverseNudges"
    },
    {
      displayName: "STREAK",
      value: "bonusStreak"
    },
    {
      displayName: "NUMBERS IN VIEW",
      value: 'bonusNumbersInView'
    },
    {
      displayName: "REEL STRIKE",
      value: "bonusOneReelStrike"
    }
  ]
}

const FeatureBoardData = {
  diceNumbers: [
  	{
  		value:1,
  		texture: new THREE.TextureLoader().load( require("./fruitMachineAssets/dice-one.png") ),
  	},
  	{
  		value:2,
  		texture: new THREE.TextureLoader().load( require("./fruitMachineAssets/dice-two.png") )
  	},
  	{
  		value:3,
  		texture: new THREE.TextureLoader().load( require("./fruitMachineAssets/dice-three.png") )
  	},
  	{
  		value:4,
  		texture: new THREE.TextureLoader().load( require("./fruitMachineAssets/dice-four.png") )
  	},
  	{
  		value:5,
  		texture: new THREE.TextureLoader().load( require("./fruitMachineAssets/dice-five.png") )
  	},
  	{
  		value:6,
  		texture: new THREE.TextureLoader().load( require("./fruitMachineAssets/dice-six.png") )
  	}
  ],
  cubeTextures: [
  	{
  		name: 'game-over',
  		texture: new THREE.TextureLoader().load( require("./fruitMachineAssets/game-over.png") ),
  		type: 'reserved',
  		outerAmountOfSquares: 1,
  		innerAmountOfSquares: 1,
  		functionToRun: 'gameOver'
  	},
  	{
  		name: 'mystery',
  		texture: new THREE.TextureLoader().load( require("./fruitMachineAssets/mystery.png") ),
  		type: 'reserved',
  		outerAmountOfSquares: 2,
  		innerAmountOfSquares: 1,
  		functionToRun: 'mystery'
  	},
  	{
   		name: 'bonus',
  		texture: new THREE.TextureLoader().load( require("./fruitMachineAssets/fbBonus.png") ),
  		type: 'reserved',
  		outerAmountOfSquares: 2,
  		innerAmountOfSquares: 1,
  		functionToRun: 'fbBonus'   		
  	},
  	{
  		name: 'arrow',
   		texture: new THREE.TextureLoader().load( require("./fruitMachineAssets/arrow.png") ),
  		type: 'reserved',
  		outerAmountOfSquares: 2,
  		innerAmountOfSquares: 2,
  		functionToRun: 'moveToBoard'
  	},
    {
      name: 'add-heart',
      texture: new THREE.TextureLoader().load( require("./fruitMachineAssets/heart.png") ),
      type: 'reserved',
      outerAmountOfSquares: 1,
      innerAmountOfSquares: 1,
      functionToRun: 'addHeart'
    },
    {
      name: 'add-icon',
      texture: new THREE.TextureLoader().load( require("./fruitMachineAssets/icon.png") ),
      type: 'reserved',
      outerAmountOfSquares: 2,
      innerAmountOfSquares: 1,
      functionToRun: 'addIcon'
    },
  	{
  		name: 'multi-add',
  		texture: new THREE.TextureLoader().load( require("./fruitMachineAssets/multi-add.png") ),
  		type: 'reserved',
  		outerAmountOfSquares:1,
  		innerAmountOfSquares:1,
  		functionToRun: 'multiAdd'
  	},
  	{
  		name: 'add-streak-strength',
  		texture: new THREE.TextureLoader().load( require("./fruitMachineAssets/plus-streak.png") ),
  		type: 'reserved',
  		outerAmountOfSquares:1,
  		innerAmountOfSquares:1,
  		functionToRun: 'addFeatureBoardValues',
  		functionToRunValues: {variable:'streakStrengthCount', values:[1, 2], direction: 'add', text:'Streak Strength'}
  	},
  	{
  		name: 'minus-money',
  		texture: new THREE.TextureLoader().load( require("./fruitMachineAssets/minus-money.png") ),
  		type: 'reserved',
  		functionToRun: 'addFeatureBoardValues',
  		functionToRunValues: {variable:'coinCount', values:[50, 100, 200, 250, 500, 'numberPicker'], direction: 'minus', text:'Coin'},
      outerAmountOfSquares:2,
      innerAmountOfSquares:0
  	},
  	{
  		name: 'add-money',
  		texture: new THREE.TextureLoader().load( require("./fruitMachineAssets/plus-money.png") ),
  		type: 'random',
  		functionToRun: 'addFeatureBoardValues',
  		functionToRunValues: {variable:'coinCount', values:[100, 200, 500, 750, 1000, 'numberPicker'], direction: 'add', text:'Coin'}
  	},
  	{
  		name: 'add-reel-strikes',
  		texture: new THREE.TextureLoader().load( require("./fruitMachineAssets/plus-strike.png") ),
  		type: 'random',
  		functionToRun: 'addFeatureBoardValues',
  		functionToRunValues: {variable:'reelStrikeCount', values:[1, 2], direction: 'add', text:'Reel Strike'}
  	},
  	{
  		name: 'add-nudges',
  		texture: new THREE.TextureLoader().load( require("./fruitMachineAssets/plus-nudge.png") ),
  		type: 'random',
  		functionToRun: 'addFeatureBoardValues',
  		functionToRunValues: {variable:'nudgeCount', values:[1, 2, 3, 4], direction: 'add', text:'Nudge'}
  	}
  ],
  diceLayouts: {
		1: [
			[0,1]
		],
		2: [
			[2,0]
		],
		3: [
			[2,1],
			[3,0],
			[0,3]
		],
		4: [
			[3,1],
			[4,0],
			[0,4]
		],
		5: [
			[2,3],
			[4,1],
			[5,0],
			[0,5]
		],
		6: [
			[2,4],
			[3,3],
			[5,1],
			[6,0],
			[0,6]
		],
		7: [
			[2,5],
			[3,4],
			[4,3],
			[6,1]
		],
		8: [
			[2,6],
			[3,5],
			[4,4],
			[5,3]
		],
		9: [
			[3,6],
			[4,5],
			[5,4],
			[6,3]
		],
		10: [
			[4,6],
			[5,5],
			[6,4]
		],
		11: [
			[5,6],
			[6,5]
		],
		12: [
			[6,6]
		]
	},
  featureBoardBonuses: [
    {
      displayName: "Taxi",
      value: "taxi"
    },
    {
      displayName: "Multi Add",
      value: "multiAdd"
    },
    {
      displayName: "UNLUCKY",
      value: "bonusUnlucky"
    },
    {
      displayName: "Extra Heart",
      value: "addHeart"
    },
    {
      displayName: "Extra Icon",
      value: "addIcon"
    },
    {
      displayName: "Move In",
      value: "moveToBoard"
    }
  ],
  featureBoardMystery: [
    {
      displayName: "Game Over",
      value: "gameOver"
    },
    {
      displayName: "Stoppa",
      value: "stoppa"
    },
    {
      displayName: "Lose with cashpot",
      value: "loseWithCashpot"
    },
    {
      displayName: "HI/LO Continue",
      value: "hiloContinue"
    },
    {
      displayName: "Continue",
      value: "continue"
    },
    {
      displayName: "Roll Even",
      value: "rollEven"
    },
    {
      displayName: "Roll Odd",
      value: "rollOdd"
    },
    {
      displayName: "Move In/Out",
      value: "moveToBoard"
    }
  ]
}

export {FruitMachineData, FeatureBoardData}