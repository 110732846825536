require('./oneUp.scss');
class OneUp {
	constructor(imgSrc, id, container=false)
	{
		this.src = imgSrc;
		this.id = id;
		this.container = container ? document.querySelector(container) : document.body;
		this.htmlDefinition();
	}

	htmlDefinition()
	{
		let container, scale, rotate, color;
  	container = document.createElement('div');
  	container.classList.add('one-up');
  	container.id = this.id;
  	scale = document.createElement('div');
  	scale.classList.add('one-up-scale');
  	rotate = document.createElement('div');
  	rotate.classList.add('one-up-rotate');
  	color = document.createElement('img');
  	color.classList.add('one-up-color');
  	color.src = this.src;
  	color.classList.add('one-up-image');
  	rotate.appendChild(color);
  	scale.appendChild(rotate);
  	container.appendChild(scale);
  	this.scale = scale;
  	this.rotate = rotate;
  	this.color = color;
  	this.oneUp = container;
  	this.container.appendChild(container);
	}

	getNode()
	{
		return this.oneUp;
	}

	up()
	{
		this.scale.classList.add('go');
		this.color.classList.add('go');
		this.rotate.classList.add('go');
	}

	down()
	{
		this.scale.classList.add('ungo');
		this.color.classList.add('ungo');
		this.rotate.classList.add('ungo');
	}

	clear()
	{
		this.scale.classList.remove('go');
		this.color.classList.remove('go');
		this.rotate.classList.remove('go');
		this.scale.classList.remove('ungo');
		this.color.classList.remove('ungo');
		this.rotate.classList.remove('ungo');
	}
}

export default OneUp;