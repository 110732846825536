import helpers from '../helpers';
import rwxFade from 'roseworx/js/effects/rwxFade';
import rwxMath from 'roseworx/js/helpers/rwxMathHelpers';

class NumberPicker
{
  constructor(selector)
  {
    this.numberPicker = document.querySelector(selector);
    if(!this.numberPicker){return;}
    this.timePerOption = 100;
    this.zStarter = 9000;
    this.stop = this.stop.bind(this);
    this.numberPicker.addEventListener('click', this.stop)
  }

  setOptions(arr)
  {
    if(arr.length == 1)
    {
      return new Promise((resolve, reject)=>{
        resolve(arr[0]);
      });      
    }
  	this.numberPicker.innerHTML = "";
    let option;
    this.options = [];
    for(let [index,val] of arr.entries())
    {
      option = document.createElement('div');
      option.classList.add('number-picker-option');
      option.setAttribute('data-value', val);
      option.appendChild(document.createTextNode(val));
      option.style.zIndex = index==arr.length-1 ? this.zStarter+1 : this.zStarter;
      this.numberPicker.appendChild(option);
      this.options.push(option);
      if(index==arr.length-1)
      {
        this.currentOptionIndex = index
      }
    }
    this.start();
    return new Promise((resolve, reject)=>{
      this.callback = resolve;
    });
  }

  stop()
  {
    clearInterval(this.interval);
    this.options[this.currentOptionIndex].classList.add('result');
    setTimeout(()=>{
    	this.callback(parseInt(this.options[this.currentOptionIndex].getAttribute('data-value')));
    	rwxFade.fadeOut(this.numberPicker);
    }, 500);
  }

  start()
  {
  	rwxFade.fadeIn(this.numberPicker);
  	let randomOption;
    this.interval = setInterval(()=>{
			while (true) {
				randomOption = rwxMath.randomInt(0,this.options.length-1);
				if (randomOption != this.currentOptionIndex){            
					break;
				} 
			}
      this.options[randomOption].style.zIndex = this.zStarter+1;
      this.options[this.currentOptionIndex].style.zIndex = this.zStarter;
      this.currentOptionIndex = randomOption;
    },this.timePerOption);
  }
}

export default NumberPicker;